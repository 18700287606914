<template>
    <div class="view-bet-sec pt-0 px-0 pb-2 px-md-0">
        <ChannelInfo
            :passChannel="passChannel"
        />
        <div>
            <div class="g-summary">
               
                <!-- ODD - EVEN -->
                <div class="sides-wrapper gp-panel-bg rounded"
                :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''">
                    <div class=" row w-100 justify-center mx-0 mt-2">
                        <v-spacer></v-spacer>
                        <div class="side-section-range large-section pb-1 side-1 side-section mx-1"
                            :class="selectedBtnClassEven ? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedEven')">
                            <div class=" text-center range-betting-header pt-4 even-color">
                                <span>{{ $t('title.even') }}</span>
                            </div>
                            <div
                                :class="selectedBtnClassEven ? 'range-betting-container-active text-center text-success w-100' : ''"  
                                class="range-betting-payout font-weight-bold">{{ evenButton ? `${evenButton.payout_display
                                } ` : '' }}</div>
      
                        </div>
                        <v-spacer></v-spacer>
                        <div class="side-section-range large-section pb-1 side-1 side-section mx-1"
                            :class="selectedBtnClassOdd ? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedOdd')">
                            <div class=" text-center range-betting-header pt-4  odd-color">
                                <!-- <img :src="_detectImg(0)" class="betting-coin-range" style="margin-top:0.8px" /> -->
                                <span>{{ $t('title.odd') }}</span>
                            </div>
                            <div
                                :class="selectedBtnClassOdd ? 'range-betting-container-active text-center text-success w-100' : ''"   
                                class="range-betting-payout font-weight-bold">{{ oddButton ? `${oddButton.payout_display}
                                                            `: '' }}</div>

                        </div>
                        <v-spacer></v-spacer>
                    </div>
                    <!-- 1 - 3 -->
                    <div class="row w-100 mt-7 mx-0">
                        <v-spacer></v-spacer>
                        <div class="side-section-range large-section pb-1 side-1 range-betting-container "
                            :class="selectedBtnClassOne ? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedOne')">
                            <div class=" text-center range-betting-header">
                                <img :src="_detectImg(0)" class="betting-coin-range " />
                            </div>
                            <div class="range-betting-payout pt-0 font-weight-bold">{{ oneButton ? `${oneButton.payout_display}
                                                            `: '' }}</div>

                        </div>
                        <v-spacer></v-spacer>
                        <div class="side-section-range large-section pb-1 side-1 range-betting-container"
                            :class="selectedBtnClassTwo ? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedTwo')">
                            <div class=" text-center range-betting-header">
                                <img :src="_detectImg(1)" class="betting-coin-range " />
                            </div>
                            <div class="range-betting-payout  pt-0 font-weight-bold">{{ twoButton ? `${twoButton.payout_display
                                } ` : '' }}</div>
   
                        </div>
                        <v-spacer></v-spacer>
                        <div class="side-section-range large-section pb-1 side-1 range-betting-container"
                            :class="selectedBtnClassThree ? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedThree')">
                            <div class=" text-center range-betting-header">
                                <img :src="_detectImg(2)" class="betting-coin-range" style="margin-top:0.8px" />
                            </div>
                            <div class="range-betting-payout pt-0 font-weight-bold">{{ threeButton ? `${threeButton.payout_display} ` : '' }}</div>

                        </div>
                        <v-spacer></v-spacer>
                    </div>
                    <!-- 4 - 6 -->
                    <div class="row w-100 mt-7 mx-0">
                        <v-spacer></v-spacer>
                        <div class="side-section-range large-section pb-1 side-1 range-betting-container "
                            :class="selectedBtnClassFour ? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedFour')">
                            <div class=" text-center range-betting-header">
                                <img :src="_detectImg(3)" class="betting-coin-range " />
                            </div>
                            <div class="range-betting-payout  pt-0 font-weight-bold">{{ fourButton ? `${fourButton.payout_display}`: '' }}</div>

                        </div>
                        <v-spacer></v-spacer>
                        <div class="side-section-range large-section pb-1 side-1 range-betting-container"
                            :class="selectedBtnClassFive ? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedFive')">
                            <div class=" text-center range-betting-header">
                                <img :src="_detectImg(4)" class="betting-coin-range " />
                            </div>
                            <div class="range-betting-payout pt-0 font-weight-bold">{{ fiveButton ? `${fiveButton.payout_display
                                } ` : '' }}</div>

                        </div>
                        <v-spacer></v-spacer>
                        <div class="side-section-range large-section pb-1 side-1 range-betting-container"
                            :class="selectedBtnClassSix ? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedSix')">
                            <div class=" text-center range-betting-header">
                                <img :src="_detectImg(5)" class="betting-coin-range" style="margin-top:0.8px" />
                            </div>
                            <div class="range-betting-payout pt-0 font-weight-bold">{{ sixButton ? `${sixButton.payout_display} ` : '' }}</div>

                        </div>
                        <v-spacer></v-spacer>
                    </div>
                    <!-- small big -->
                    <div class=" row w-100 justify-center mx-0 mt-7">
                        <v-spacer></v-spacer>
                        <div class="side-section-range large-section pb-1 side-1 side-section mx-1"
                            :class="selectedBtnClassSmall ? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedSmall')">
                            <div class=" text-center range-betting-header pt-4 small-color">
                                <span>{{ $t('title.small') }}</span>
                            </div>
                            <div
                                :class="selectedBtnClassSmall ? 'range-betting-container-active text-center text-success w-100' : ''" 
                                class="range-betting-payout font-weight-bold">{{ smallButton ? `${smallButton.payout_display
                                } ` : '' }}</div>

                        </div>
                        <v-spacer></v-spacer>
                        <div class="side-section-range large-section pb-1 side-1 side-section mx-1"
                            :class="selectedBtnClasslarge ? 'range-betting-container-active' : ''"
                            @click="_allowBtnBet('selectedBig')">
                            <div class=" text-center range-betting-header pt-4  big-color" >
                                <!-- <img :src="_detectImg(0)" class="betting-coin-range" style="margin-top:0.8px" /> -->
                                <span>{{ $t('title.large') }}</span>
                            </div>
                            <div 
                            :class="selectedBtnClasslarge ? 'range-betting-container-active text-center text-success w-100' : ''" 
                            class="range-betting-payout font-weight-bold">{{ bigButton ? `${bigButton.payout_display}
                                                            `: '' }}</div>

                        </div>
                        <v-spacer></v-spacer>
                    </div>




                </div>
                <CoinButton 
                    :passGetButton="passGetButton" 
                    :passChannel="passChannel"
                    :passPlaceCoin="bettingMoney"
                    v-on:handleInputMonay="_inputAmount"/>
                    <SubmitBtn  
            :passCheckAllowSubmit="_checkAllowSubmit"
            v-on:handleSubmitBtn="_submitBetting"
            v-on:handleClearBtn="_clearMoney"/>
                <LimitBetting/>
            </div>
        </div>
    </div>
</template>

<script>
import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import CoinButton from '../../../../components/CoinBettingButton.vue';
import ChannelInfo from '../../../../components/ChannelInfo.vue';
import LimitBetting from '../../../../components/LimitBetting.vue';
import SubmitBtn from '../../../../components/SubmitBtn.vue';
import { mapGetters, mapMutations } from "vuex";
export default {
    components: { CoinButton, ChannelInfo, LimitBetting, SubmitBtn },
    props: ["passChannel",
        "passCurrentBetting",
        'passChannelStatus',
        'passGetButton',
        'passBettingTypeMaster',
        'passWeight',
        'passSelectedChannel'
    ],
    data() {
        return {
            selectedButton: {},
            bettingMoney: 0,
            valueTiger: "",
            resultTiger: '',
            valueDragon: '',
            resultDragon: '',
            selectedBtnClassSmall: false,
            selectedBtnClasslarge: false,
            countDown: {},
            selectedBtnClassOne: false,
            selectedBtnClassTwo: false,
            selectedBtnClassThree: false,
            selectedBtnClassFour: false,
            selectedBtnClassFive: false,
            selectedBtnClassSix: false,
            selectedBtnClassOdd : false,
            selectedBtnClassEven : false,
            selectedCoin: false
        }

    },

    computed: {
        oneButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[0]
            else return {}
        },
        twoButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[1]
            else return {}
        },
        threeButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[2]
            else return {}
        },
        fourButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[3]
            else return {}
        },
        fiveButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[6]
            else return {}
        },
        sixButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[7]
            else return {}
        },
        evenButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[5]
            else return {}
        },
        oddButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[4]
            else return {}
        },
        bigButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[8]
            else return {}
        },
        smallButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[9]
            else return {}
        },
        _checkAllowSubmit() {
            if (  this.bettingMoney> 0 && 
                (this.selectedBtnClassFive || this.selectedBtnClassFour || this.selectedBtnClassThree 
                || this.selectedBtnClassTwo || this.selectedBtnClassOne || this.selectedBtnClassSmall || this.selectedBtnClasslarge || this.selectedBtnClassEven || this.selectedBtnClassOdd)
                ) return true;
            else  return false;
        },
        ...mapGetters("$_dashboard", {
            dialogBetting: "getDialogBetting",
            amountMin: "getAmountMin",
            amountMax: "getAmountMax",
            getCountdown: "getCountdown"
        }),
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },

    methods: {

        formatBtnCurrencyKh(amount) {
            switch (true) {
                case (amount >= 1000 && amount <=9999):
                    return amount / 1000 + "ព";
                case (amount >= 10000 && amount <=999999):
                    return amount / 10000 + "ម";
                case (amount >= 1000000 && amount <=99999999999):
                    return amount / 1000000 + "ល";
                default:
                    return amount
            }
        },
        formatBtnCurrency(amount) {
            switch (true) {
                case (amount >= 1000 && amount <=9999):
                    return amount / 1000 + "K";
                default:
                    return amount
            }
        },
        formatCoinCurrency(amount){
            switch (amount) {
                case 5000:
                    return '5ព';
                case 10000:
                    return '1ម';
                case 20000:
                    return '2ម';
                case 50000:
                    return '5ម';
                case 100000:
                    return '10ម';
                case 200000:
                    return '20ម';
                default:
                    return amount;
            }
           
        },
        checkSattus() {
            if (this.passChannel.is_open == null) {
                return "Not_Open"
            } else {
                if (this.passChannel.is_open) {
                    return "Open"
                }
                return "Close"
            }
        },
        checkStatus() {
            if (this.passChannel.is_open == null) {
                return "text-warning"
            } else {
                if (this.passChannel.is_open) {
                    return "text-success"
                }
                return "text-danger"
            }
        },
        _detectCoin(index) {
            switch (index) {
                case 0:
                    return '/images/coins/blue.png';
                case 1:
                    return '/images/coins/pink.png';
                case 2:
                    return '/images/coins/cyan.png';
                case 3:
                    return '/images/coins/red.png';
                case 4:
                    return '/images/coins/orange.png';
                case 5:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyCoinsmall(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorsmall(money) {
            switch (true) {
                case money < 5:
                    return 'black';
                case money < 10:
                    return 'black';
                case money < 20:
                    return 'black';
                case money < 50:
                    return 'black';
                case money < 100:
                    return 'black';
                case money < 99999999:
                    return 'black';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyCoinlarge(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorlarge(money) {
            switch (true) {
                case money < 5:
                    return 'black';
                case money < 10:
                    return 'black';
                case money < 20:
                    return 'black';
                case money < 50:
                    return 'black';
                case money < 100:
                    return 'black';
                case money < 99999999:
                    return 'black';
                default:
                    return ''
            }
        },
        _detectImg(index) {
            switch (index) {
                case 0:
                    return '/images/taisiev/1.png';
                case 1:
                    return '/images/taisiev/2.png';
                case 2:
                    return '/images/taisiev/3.png';
                case 3:
                    return '/images/taisiev/4.png';
                case 4:
                    return '/images/taisiev/5.png';
                case 5:
                    return '/images/taisiev/6.png';
                case 7:
                    return "/images/taisiev/odd.png";
                case 8:
                    return "/images/taisiev/small.png";
                case 6:
                    return "/images/taisiev/even.png";
                case 9:
                    return "/images/taisiev/big.png";
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorTie(money) {
            switch (true) {
                case money < 5:
                    return 'black';
                case money < 10:
                    return 'black';
                case money < 20:
                    return 'black';
                case money < 50:
                    return 'black';
                case money < 100:
                    return 'black';
                case money < 99999999:
                    return 'black';
                default:
                    return ''
            }
        },
        _detectColor(index) {
            switch (index) {
                case 0:
                    return 'black';
                case 1:
                    return 'black';
                case 2:
                    return 'black';
                case 3:
                    return 'black';
                case 4:
                    return 'black';
                case 5:
                    return 'black';
                default:
                    return ''
            }
        },
        _openDialogBetting(selectedButton) {
            this.selectedButton = selectedButton;
            this.BETTING_DIALOG(true);
        },
        _closeDialogBetting() {
            this.bettingMoney = 0;
            this.BETTING_DIALOG(false);
        },
        _inputAmount(money) {
            
                this.selectedCoin = true;
                this.bettingMoney = parseInt(this.bettingMoney);
                this.bettingMoney += parseInt(money)

        },
        _clearMoney() {
            this.bettingMoney = 0;
            this.selectedBtnClassSmall = false;
            this.selectedBtnClassOne = false;
            this.selectedBtnClasslarge = false;
            this.selectedBtnClassTwo = false;
            this.selectedBtnClassThree = false;
            this.selectedBtnClassFour = false;
            this.selectedBtnClassFive = false;
            this.selectedBtnClassSix = false;
            this.selectedCoin = false;
            this.selectedBtnClassEven = false;
                    this.selectedBtnClassOdd = false;
        },
        _submitBetting() {
            let submitBettingData = {
                type_id: this.selectedButton._id,
                amount: this.bettingMoney
            };
            this.$emit("sentSubmitBetting", submitBettingData);
            this._clearMoney();
        },
        _allowBtnBet(selectedBtn) {
            if (!this.selectedCoin ) {
                this.$toastr.e(this.$t('title.selectCoin'));
                return false;
            }
            switch (selectedBtn) {
                case 'selectedOne':
                    this.selectedButton = this.oneButton;
                    this.selectedBtnClassOne = true;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClasslarge = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClasslarge = false;
                    break;
                case 'selectedTwo':
                    this.selectedButton = this.twoButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = true;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClasslarge = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClasslarge = false;
                    break;
                case 'selectedThree':
                    this.selectedButton = this.threeButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = true;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClasslarge = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClasslarge = false;
                    break;
                case 'selectedFour':
                    this.selectedButton = this.fourButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = true;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClasslarge = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClasslarge = false;
                    break;
                case 'selectedFive':
                    this.selectedButton = this.fiveButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = true;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClasslarge = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClasslarge = false;
                    break;
                case 'selectedSix':
                    this.selectedButton = this.sixButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = true;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClasslarge = false;
                    break;
                case 'selectedEven':
                    this.selectedButton = this.evenButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassEven = true;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClasslarge = false;
                    break;
                case 'selectedOdd':
                    this.selectedButton = this.oddButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassOdd = true;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClasslarge = false;
                    break;
                case 'selectedSmall':
                    this.selectedButton = this.smallButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassSmall = true;
                    this.selectedBtnClasslarge = false;
                    break;
                case 'selectedBig':
                    this.selectedButton = this.bigButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassEven = false;
                    this.selectedBtnClassOdd = false;
                    this.selectedBtnClassSmall = false;
                    this.selectedBtnClasslarge = true;
                    break;
            }
        },
        ...mapMutations("$_dashboard", [
            "BETTING_DIALOG",
            "UPDATE_ABONG_CARD",
            "RESET_ABONG_CARD"
        ]),
    }
}
</script>
<style scoped>
@media(min-width:960px) {
    .money-in-coin-range-position {
        margin-top: -34px;
        margin-bottom: 14px;
        z-index: 100;
        font-weight: 900;
        width: 100%;
        text-align: center;
        font-size: 12px;
        text-shadow: 1px 0px black;
    }

    .betting-coin-range {
        width: 50px;
        height: auto;
        margin: auto;
        /* margin-top: -50px; */
        z-index: 1000 important
    }

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 100px;
        border: 2px solid white;
        border-radius: 5px;
    }

    .betting-coin-small {
        width: 40px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-large {
        width: 40px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 45px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }


    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -29.5px;
        margin-bottom: 25px;
        margin-left: -2px;
        z-index: 100;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        font-size: 13px;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 1px;
        left: -16px;
        font-size: 12.5px;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        width: 70px;
    }
}

@media(min-width:1280px) {

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }

    .betting-coin-large {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .betting-coin-small {
        width: 50px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-range {
        width: 50px;
        height: auto;
        margin: auto;
    }

    .betting-coin-large {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -35px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
    }

    .money-in-coin-range-position {
        margin-top: -34px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
        width: 100%;
        text-align: center;
        font-size: 12px;
        text-shadow: 1px 0px black;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 7px;
        left: -8px;
        font-size: 16px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
    }

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5);
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: rgb(0, 102, 153, .5)
    }
}

.open-betting-countdown {
    background-color: #def3f6;
    font-size: 22px;
    font-family: 'khmer mef1';
    font-weight: 900;
    height: 42px;
    text-align: center;
    padding-top: 6px;
    text-shadow: 0.2px 0.2px red;
}

.open-betting {
    color: #28a745;
}

.close-betting {
    color: #dc3545;
}

.range-betting-container {
    width: 20%;
    height: 90px;
    border-radius: 5px;
    background-color: rgb(0, 102, 153, .5);
    border: 2px solid white;
}

.range-betting-container-active {
    width: 20%;
    height: 90px;
    border-radius: 5px;
    background-color: #fec84e!important;
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
}

.range-betting-container:hover {
    cursor: pointer;
}

.range-betting-header {
    width: 100%;
    height: 74%;
    padding-top: 5px !important;
    /* background-color: rgba(32, 218, 88, 0.2); */
    /* border-bottom: 3px solid rgb(218, 165, 32); */
    color: lightgoldenrodyellow;
    text-shadow: 1px 0px black;
    padding-top: 0px;
    font-weight: 700;
    font-size: 27px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.range-betting-payout {
    text-align: center;
    padding-top: 3px;
    background-color:#003366;
}
.even-color{
    background-color: #0E599C;
}
.odd-color{
    background-color: #BF1A00;
}
.small-color{
    background-color: #FF8900;
}
.big-color{
    background-color: #00830B;
}
.img-coin-range {
    width: 100%;
    display: grid;
    margin-top: -70px
}
</style>