<template>
  <div>
    <div class="lotto-nav-head">
      <span class="span-title pt-2">{{ $t("title.outstanding") }}</span>
    </div>
    <v-row no-gutters>
      <v-col cols="12">
        <div class="list-result-head">
          <ul class="mb-0">
            <li>
              <span class="mt-5"> {{ $t("title.total_betting_an_invoice") }}  </span>
              <span class="float-right pr-5 " style="font-weight:bold" :style="getTotalInvoice > userInfor.balance ? 'color:red' :'color:green'">
                {{ getTotalInvoice  ?  getTotalInvoice.toLocaleString('en'): '0.00' }} {{ _displayCurrencySymbol(userInfor.account_currency) }}
              </span>
            </li>
            <li>
              <span class="mt-5"> {{ $t("title.total_betting_a_game") }}  </span>
              <span class="float-right pr-5 " style="font-weight:bold">
                {{ currentBetting && currentBetting.total_amount ?  currentBetting.total_amount.toLocaleString('en'): '0.00' }}  {{ _displayCurrencySymbol(userInfor.account_currency) }}
              </span>
            </li>
          </ul>
        </div>
        <div>
          <div>
            <div class="title-current">
              {{ $t("title.lo_current_betting") }}
            </div>
            <h6
              class="sub-title pr-1 pl-2"
              style="color: black"
              v-if="
                currentBetting && currentBetting.list && currentBetting.count > 0
              "
            >
              {{
                currentBetting && currentBetting.list
                  ? moment(currentBetting.date).format("YYYYMMDD") +
                    "#" +
                    currentBetting.list[0].no
                  : "loading..."
              }}
              <span class="float-right ">
                <button
                  class="btn-link link print-all"
                  @click="openPrintMultiple()"
                >
                  {{ $t("title.ticket") }}
                </button>
              </span>
            </h6>
          </div>
          <div class="list-result-body current-betting-lottery-list">
            <table class="tb-bet">
              <tr
                v-for="(item, i) in currentBetting.list"
                :key="i"
                class="ng-star-inserted current-betting-tr"
              >
              <td>


                <div class="my-table-view ">
                  <div class="my-td-view my-text-g-left list-value">
                    {{ item  && item.total_amount ? item.total_amount.toLocaleString('en') : '' }}  {{ _displayCurrencySymbol(userInfor.account_currency) }}
                  </div>
                  <div class="my-td-view my-text-g-left">
                    <button
                    class="btn-link link pl-1"
                    @click="_goToDetail(item)"
              >
                    <span class="fontKhmer"     >{{ $t("button.detail") }}</span>
                  </button>
                  </div>
         
                  <div class="my-td-view my-text-g-right">
                    <button
                          class="btn-link link "
                          @click="printTicket(item, item.invoice_no)"
                          
                        >
                        
                          <span class="fontKhmer">{{ $t("title.ticket") }}</span>
                        </button>
                        <input
                          type="checkbox"
                          :value="[{ item }]"
                          v-model="selectedAddOns"
                          class="myCheck"
                        />

                  </div>
                  <div class="my-td-view-colspan xx-detail xx-detail-hide my-text-g-left">
                    <ul>
                           <li >{{ $t("print.invoice_no") }}: <span class="list-value">{{ item.invoice_no }}</span></li>
                           <li>{{ $t("invoice.game_id") }}: <span class="list-value">{{ item.no }} </span></li> 
                           <li>{{ $t("title.outstanding") }}: <span class="list-value">{{item  &&  item.total_amount? item.total_amount.toLocaleString('en'):'' }}</span></li>
                           <li>{{ $t("title.total_amount_win") }}: <span class="list-value">{{ ' ??' }} </span></li>
                           <li>{{ $t("invoice.date") }}: <span class="list-value">{{ item.time }}</span></li>
                           <li>{{ $t("title.post_channel") }}: <span class="list-value">
                                  <span v-if="item.bet_a.number.length>0">A</span>
                                  <span v-if="item.bet_b.number.length>0"> B</span>
                                  <span v-if="item.bet_c.number.length>0"> C</span>
                                  <span v-if="item.bet_d.number.length>0"> D</span>
                                  <span v-if="item.bet_e.number.length>0"> E</span></span>
                          </li>
                          <li v-if="item.bet_a">{{ $t("invoice.bet_point") }}: <span class="list-value">{{ item.bet_a.payout }}</span> </li>
                          <!-- <li>លេខឆ្នោត : {{ items.is_release_result }}</li> -->
                        </ul>

                  </div>
                </div>
              </td>
             </tr>
            </table>
          </div>
        </div>
        <div>
          <div>
            <div class="title-current">
              {{ $t("title.lo_previous_betting") }}
            </div>
            <h6
            class="sub-title pr-1 pl-2"
            style="color: black"
            v-if="
            previousBetting &&
            previousBetting.length &&
            previousBetting.length > 0
            "
          >
            {{
              previousBetting
                ? moment(previousBetting.time).format("YYYYMMDD") +
                  "#" +
                  previousBetting[0].no
                : "loading..."
            }}
            <span class="float-right">
              <button
                class="btn-link link print-all"
                @click="openPrintMultiple()"
              >
                {{ $t("title.ticket") }}
              </button>
            </span>
          </h6>
          </div>
          <div class="list-result-body previous-betting-lottery-list">
            <table class="tb-bet">
              <tr
                v-for="(item, i) in previousBetting"
                :key="i"
                class="ng-star-inserted current-betting-tr"
              >
              <td>
                <div class="my-table-view ">
                  <div class="my-td-view my-text-g-left list-value">
                    {{ item  && item.total_amount ? item.total_amount.toLocaleString('en') : '' }}  {{ _displayCurrencySymbol(userInfor.account_currency) }}
                  </div>
                  <div class="my-td-view my-text-g-left">
                    <button
                          class="btn-link link"
                          @click="_goToDetail(item)"
                          >
                        
                          <span class="fontKhmer">{{ $t("button.detail") }}</span>
                        </button>
                  </div>
                  <div class="my-td-view my-text-g-right">
                    <button
                          class="btn-link link"
                          @click="printTicket(item, item.invoice_no)"
                          
                        >
                        
                          <span class="fontKhmer">{{ $t("title.ticket") }}</span>
                        </button>
                        <input
                          type="checkbox"
                          :value="[{ item }]"
                          v-model="selectedAddOns"
                          class="myCheck"
                        />

                  </div>
                  <div class="my-td-view-colspan xx-detail xx-detail-hide my-text-g-left">
                    <ul>
                           <li >{{ $t("print.invoice_no") }}:<span class="list-value">{{ item._id }}</span></li>
                           <li>{{ $t("invoice.game_id") }}: <span class="list-value">{{ item.no }}</span> </li> 
                           <li>{{ $t("title.outstanding") }}:<span class="list-value"> {{item  &&  item.total_amount? item.total_amount.toLocaleString('en')  + _displayCurrencySymbol(userInfor.account_currency) :'' }}</span></li>
                           <li>{{ $t("title.total_amount_win") }}: <span class="list-value">{{ item  &&  item.total_cast? item.total_cast.toLocaleString('en') : '' }}</span> </li>
                           <li>{{ $t("invoice.date") }}: <span class="list-value">{{ item.time }}</span></li>
                           <li>{{ $t("title.post_channel") }}: <span class="list-value">
                                  <span v-if="item.bet_a.number.length>0">A</span>
                                  <span v-if="item.bet_b.number.length>0"> B</span>
                                  <span v-if="item.bet_c.number.length>0"> C</span>
                                  <span v-if="item.bet_d.number.length>0"> D</span>
                                  <span v-if="item.bet_e.number.length>0"> E</span></span>
                          </li>
                          <li v-if="item.bet_a">{{ $t("invoice.bet_point") }}: <span class="list-value">{{ item.bet_a.payout }}</span></li>
                          <!-- <li>លេខឆ្នោត : {{ item.is_release_result }}</li> -->
                        </ul>

                  </div>
                </div>
              </td>
             </tr>
            </table>
          </div>
        </div>
      </v-col>
    </v-row>
        <!-- Print One Ticket On Select -->
        <v-dialog v-model="dialogPrintTicket" persistent max-width="500px">
          <div id="printMe" hidden>
            <div style="max-width: 260px; margin-left: 0px" v-if="items">
              <div class="kh-mef-14" style="text-align: center">
                <span style="font-size: 18px" class="fontKhmer">
                  {{ userInfor.user_name }} </span
                >
                <div>
                  <span style="font-size: 14px" class="fontKhmer">{{
                    drawTicketLineOnPrint
                  }}</span>
                </div>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.ticket_id") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{ items._id }}
                </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.game_id") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{
                    items.time
                      ? items.time.split("-").join("").slice(0, 8) + "#" + items.no
                      : "loading..."
                  }}
                </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.date") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{ items.time }}
                </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.type_betting") }}:
                </span><br/>
                  <span class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                  " ><br/>
                    <span v-if="items && items.bet_a && items.bet_a.number.length>0">A : {{ items.bet_a.number.join(', ') }} <br/></span> 
                    <span v-if="items && items.bet_b && items.bet_b.number.length>0">B : {{ items.bet_b.number.join(', ') }}<br/></span>
                    <span v-if="items && items.bet_c && items.bet_c.number.length>0">C : {{ items.bet_c.number.join(', ') }}<br/></span>
                    <span v-if="items && items.bet_d && items.bet_d.number.length>0">D : {{ items.bet_d.number.join(', ') }}<br/></span>
                    <span v-if="items && items.bet_e && items.bet_e.number.length>0">E : {{ items.bet_e.number.join(', ') }}<br/></span>
                  </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.bet_point") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{ items.total_amount }} x 90
                </span>
              </div>
              <div style="display: block; width: 260px">
                <span
                  class="fontKhmer"
                  style="
                    width: 80px;
                    font-weight: bold;
                    font-size: 13px;
                    padding-right: 0px;
                    margin-right: 0px;
                    margin-left: 9px;
                  "
                >
                  {{ $t("invoice.win_loss") }}:
                </span>
                <span
                  class="fontKhmer"
                  style="
                    width: 180px;
                    font-weight: bold;
                    font-size: 13px;
                    margin-left: 15px;
                  "
                >
                  {{ items.total_cast }}
                </span>
              </div>
              <div style="margin-left: 10px">
                <span style="font-size: 14px" class="fontKhmer">{{
                  drawTicketLineOnPrint
                }}</span>
              </div>
              <div style="text-align: center; margin-top: -30px">
                <p
                  class="fontKhmer"
                  style="font-size: 16px; text-align: center; margin-left: -10px"
                >
                  {{ WebsiteName }}
                </p>
              </div>
            </div>
          </div>
          <v-card class="justify-center">
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1"  style="width:15%"  @click="closePrintDialog()">{{
                $t("invoice.cancel")
              }}</v-btn>
              <v-btn color="red darken-1"  style="width:15%"  @click="print()">{{
                $t("invoice.print")
              }}</v-btn>
            </v-card-actions>
            <v-card-title class="justify-center username-title">
              pp78.net
            </v-card-title>
            <v-card-title class="justify-center draw-ticket-line">
              <span>{{ drawTicketLine }}</span>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.account") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1">
                 {{ userInfor.user_name }}
              </v-flex>
            </v-card-title>
          
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.invoice_no") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1">
                {{ items.invoice_no }}
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("title.game_no") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1 ">
                <span>
                  {{
                    items.no
                  }}
                </span>
              
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.number") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1" >
                <span v-if="items && items.bet_a && items.bet_a.number.length>0">A : {{ items.bet_a.number.join(', ') }} <br/></span>
                <span v-if="items && items.bet_b && items.bet_b.number.length>0">B : {{ items.bet_b.number.join(', ') }}<br/></span>
                <span v-if="items && items.bet_c && items.bet_c.number.length>0">C : {{ items.bet_c.number.join(', ') }}<br/></span>
                <span v-if="items && items.bet_d && items.bet_d.number.length>0">D : {{ items.bet_d.number.join(', ') }}<br/></span>
                <span v-if="items && items.bet_e && items.bet_e.number.length>0">E : {{ items.bet_e.number.join(', ') }}<br/></span>
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.time") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1">
                {{ items.time }}
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center field-title">
              <v-flex md3 sm3> {{ $t("invoice.bet_point2") }}: </v-flex>
              <v-flex md6 sm6 class="justify-start ml-1">
                <span>
                  {{items.total_amount}} x 90 = {{ items.is_release_result == false ? "???" : items.total_cast}}
                </span>
                
              </v-flex>
            </v-card-title>
            <v-card-title class="justify-center draw-ticket-line">
              <span>{{ drawTicketLine }}</span>
            </v-card-title>
            <v-card-title style="margin-top: -25px" class="justify-center">
              <span style="font-size: 14px; opacity: 0.5">{{ WebsiteName }}</span>
            </v-card-title>
          </v-card>
        </v-dialog>
    
        <!-- Print selected tickets -->
    
        <v-dialog
          v-model="dialogPrintTicketMulti"
          persistent
          max-width="500px"
          style="margin-top: 170px !important"
        >
          <div id="printMeMultiTicket" hidden>
            <div
              style="max-width: 260px; margin-left: 0px"
              v-for="(item, i) in this.selectedAddOns"
              :key="i"
            >
              <div class="kh-mef-14">
                <div class="kh-mef-14" style="text-align: center">
                  <span
                    class="fontKhmer"
                    style="font-size: 28px; margin-left: -25px"
                  >
                    {{ userInfor.user_name }}</span
                  >
                  <div>
                    <span class="fontKhmer" style="font-size: 18px">{{
                      drawTicketLine
                    }}</span>
                  </div>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.ticket_id") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >
                    {{ item[0].item._id }}
                  </span>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.game_id") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >
                    {{
                      item[0].item.time
                        ? item[0].item.time.split("-").join("").slice(0, 8) +
                          "#" +
                          item[0].item.no
                        : "loading..."
                    }}
                  </span>
                </div>
                <div class="row">
                  <div
                    class="col-5 fontKhmer"
                    style="font-weight: bold; font-size: 12.5px"
                  >
                    {{ $t("invoice.date") }}:
                  </div>
                  <div
                    class="col-5 fontKhmer"
                    style="
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: -25px;
                    "
                  >
                    {{ item[0].item.time }}
                  </div>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.type_betting") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >
                    <span v-if="$i18n.locale == 'khmer'">
                      {{
                        item[0].item.betting
                      }}
                    </span>
                    <span v-else-if="$i18n.locale != 'khmer'">
                      {{
                        item[0].item.betting 
                      }}
                    </span>
                  </span>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.bet_point") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >
                    {{ item[0].item.amount }}
                  </span>
                </div>
                <div style="display: block; width: 260px">
                  <span
                    class="fontKhmer"
                    style="
                      width: 80px;
                      font-weight: bold;
                      font-size: 13px;
                      padding-right: 0px;
                      margin-right: 0px;
                      margin-left: 9px;
                    "
                  >
                    {{ $t("invoice.win_loss") }}:
                  </span>
                  <span
                    class="fontKhmer"
                    style="
                      width: 180px;
                      font-weight: bold;
                      font-size: 13px;
                      margin-left: 15px;
                    "
                  >
                    {{ item[0].item.is_win == null ? "???" : item[0].item.is_win }}
                  </span>
                </div>
                <div>
                  <span style="font-size: 18px; opacity: 0.9">{{
                    drawTicketLine
                  }}</span>
                </div>
                <div style="text-align: center">
                  <p
                    class="fontKhmer"
                    style="font-size: 16px; text-align: center; margin-left: -10px"
                  >
                    {{ WebsiteName }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <v-card class="justify-center">
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1"  style="width:15%"  @click="closePrintMultiDialog()">{{
                $t("invoice.cancel")
              }}</v-btn>
              <v-btn color="red darken-1"  style="width:15%"  @click="printMulti()">{{
                $t("invoice.print")
              }}</v-btn>
            </v-card-actions>
            <div v-for="(item, i) in this.selectedAddOns" :key="i">
              <v-card-title class="justify-center username-title">
                {{ userInfor.user_name }}
              </v-card-title>
              <v-card-title class="justify-center draw-ticket-line">
                <span>{{ drawTicketLine }}</span>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.ticket_id") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{ item[0].item._id }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.game_id") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{
                    item[0].item.time
                      ? item[0].item.time.split("-").join("").slice(0, 8) +
                        "#" +
                        item[0].item.no
                      : ""
                  }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.date") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{ item[0].item.time }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.type_betting") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1 pl-5">
                  <span v-if="$i18n.locale == 'khmer'">
                    {{
                      item[0].item.betting
                    }}
                  </span>
                  <span v-else-if="$i18n.locale != 'khmer'">
                    {{
                      item[0].item.betting
                    }}
                  </span>
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.bet_point") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{ item[0].item.amount }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center field-title">
                <v-flex md3 sm3> {{ $t("invoice.win_loss") }}: </v-flex>
                <v-flex md6 sm6 class="justify-start ml-1">
                  {{ item[0].item.is_win == null ? "???" : item[0].item.is_win }}
                </v-flex>
              </v-card-title>
              <v-card-title class="justify-center draw-ticket-line">
                <span>{{ drawTicketLine }}</span>
              </v-card-title>
              <v-card-title style="margin-top: -25px" class="justify-center">
                <span style="font-size: 14px; opacity: 0.5">{{ WebsiteName }}</span>
              </v-card-title>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog
        :value="dialog"
        max-width="1200"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar style="float: right; border-radius:55px; background-color: #1e1e1e;" class="mt-3" >
          <v-btn icon color="error" @click="dialog=false" class="mb-1" >
            <v-icon style="color:#fb8c00 !important" >mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-0">
            <v-col cols="12" sm="12" md="12" class="text-left mt-4">
              <v-toolbar-title class="dialog-title">{{ $t("title.invoice_detail") }}</v-toolbar-title>
            </v-col>
          </v-row>
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1 detail-resport"
              item-key="date"
              :headers="headersDetail"
              :footer-props="footerProps"
              :items="detailData ? detailData : []"
              :server-items-length="detailData ? parseInt(detailData.count): null"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              @update:page="updatePage"
              @update:items-per-page="updatePage"
              @update:sort-desc="updatePage"
              :loading="getLoadingDetail"
              mobile-breakpoint="0"
          >
            
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>{{ item.time }}</td>
                <td>{{ item.no }}</td>
                <td>
                  <span :style="{color: item.bet_color}">{{item.bet_type}}:{{item.betting}}  </span>
                </td>
                <td>{{item.amount ? item.amount.toLocaleString("en"):"..." }}</td>
               
                <td>{{ item.return_amount }}</td>
                <td :style="item.is_release_result && item.bet_result.is_win ? 'color:blue' : item.is_release_result && !item.bet_result.is_win ? 'color:red' : '---'">{{ item.is_release_result && item.bet_result.is_win ? $t("title.x_win") : item.is_release_result && !item.bet_result.is_win ? $t("title.x_lose") : '---' }}</td>
                 <td>
                    <span :style="{color: item.bet_color}"> <span  v-if="item.bet_type=='A'">{{item.bet_type}}:{{ item.bet_result.a.number }}</span></span>
                    <span :style="{color: item.bet_color}"> <span  v-if="item.bet_type=='B'">{{item.bet_type}}:{{ item.bet_result.b.number }}</span></span>
                    <span :style="{color: item.bet_color}"> <span  v-if="item.bet_type=='C'">{{item.bet_type}}:{{ item.bet_result.c.number }}</span></span>
                    <span :style="{color: item.bet_color}"> <span  v-if="item.bet_type=='D'">{{item.bet_type}}:{{ item.bet_result.d.number }}</span></span>
                    <span :style="{color: item.bet_color}"> <span  v-if="item.bet_type=='E'">{{item.bet_type}}:{{ item.bet_result.e.number }}</span></span>
                 </td> 
              </tr>
            </template>
            <template slot="body.append">
              <td :colspan="3" class="text-xs-center pl-4 py-2 ">
                {{ $t("title.total") }}
              </td>
              <td :class=" selectedItem.total_amount < 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">
               
                {{ selectedItem ?  _formatAmountCurrency(userInfor, selectedItem.total_amount) : "loading ..."}}  
              </td>
              <td :class=" selectedItem.total_amount_win < 0 ? 'lose-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold' :'win-color font-weight-bold  text-xs-center total-style pl-4  py-2 font-weight-bold'">
               
                {{ selectedItem && ( selectedItem.total_cast || selectedItem.total_cast == 0)  ? _formatAmountCurrency(userInfor, selectedItem.total_cast) : "???"}} 
              </td>
              <td></td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import "@/assets/css/lottery.css";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";
import Mixin from "../../../../mixin/index";
export default {
  // DEFINE local VARIABLE
  data() {
    return {
      mixins: [Mixin],
      message: "Hello world",
      previousGame: {},
      current: {},
      dialogPrintTicket: false,
      dialogPrintTicketMulti: false,
      format: "YYYY-MM-DD",
      selectedAddOns: [],
      items: [],
    ticket_id: "",
    drawTicketLine: "--------------------------------------------------------",
    drawTicketLineOnPrint: "------------------------------------------------",
    WebsiteName: "pp77",
    headersDetail: [
    
      { text: 'កាលបរិច្ឆេទ', value: 'time' },
      { text: 'ហ្គេម', value: 'no' },
      { text: 'ចាក់ប្រភេទ', value: 'bet_type' },
      { text: 'ទឹកប្រាក់', value: 'amount' },
     
      { text:'ប្រាក់សងត្រលប់',value:'total_cast'},
      { text: 'លទ្ធផល',value:'result'},
      { text: 'ឆ្នោតលេខ', value: 'result_number'}

    ],
    detailData: [],
    dialog: false,
    footerProps: {
      "items-per-page-options": [10, 20, 50, 100, 200],
    },
    dataRequest:{
      descendingFormat: [true],
      sortByFormat: ["_id"],
      descending: true,
      sortBy: null,
      page: 1,
      rowsPerPage: 10,
    },
    getLoadingDetail:false,
    invoice_id: null,
    betALength: {},
    betBLength: {},
    betCLength: {},
    betDLength: {},
    betELength: {},
    labelA:null,
    labelB:null,
    labelC:null,
    labelD:null,
    labelE:null,
    selectedItem:{}
    }},
  computed: {
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapGetters("$_machine_lottery", {
      latestResults: "getLastResults",
      getLastFiveResults: "getLastFiveResults",
      currentBetting: "getCurrentBetting",
      previousBetting: "getPreviousBetting",
      currentGame: "getCurrentGame",
      countTime: "getCountTime",
      getTotalInvoice:'getTotalInvoice'
    }),
  },
  methods: {
    moment,
    _goToDetail(data){
      this.selectedItem = data;
      this.invoice_id = data.invoice_no;
      console.log("data",data)
      this.loadDetailData(data.invoice_no);
      this.dialog = true;
    },
    updatePage() {
      this.getLoadingDetail = true;
      this.loadDetailData(this.invoice_id);  
      this.getLoadingDetail = false;
    },
    async loadDetailData(_id){
      this.detailData = [],
      this.getLoadingDetail = true;
       this.dataRequest.sortByFormat.length < 1
        ? (this.dataRequest.sortBy = "_id._id")
        : (this.dataRequest.sortBy = this.dataRequest.sortByFormat[0]);
      this.dataRequest.descendingFormat.length > 0
        ? (this.dataRequest.descending = this.dataRequest.descendingFormat[0])
        : (this.dataRequest.descending = true);
        
        const response = await this.$request.getSlot({
            url: 'm_lottery/report/detail?module_bet_id=' + _id
        });
      if (response.data.code) {
        this.detailData = response.data.data;
        this.getLoadingDetail = false;
      }
    },
    printTicket(item, ticket_id) {
      this.items = item;
      this.ticket_id = ticket_id;
      this.dialogPrintTicket = true;
    },
    printTicket2(item) {
      // console.log('@item:',item);
      this.items = item;
      this.betALength = item.bet_a.number.length
      this.betBLength = item.bet_b.number.length
      this.betCLength = item.bet_c.number.length
      this.betDLength = item.bet_d.number.length
      this.betELength = item.bet_e.number.length
      this.labelA = item.bet_a.label
      this.labelB = item.bet_b.label
      this.labelC = item.bet_c.label
      this.labelD = item.bet_d.label
      this.labelE = item.bet_e.label
      // console.log('printTicket2:',this.bet_a);
      this.dialogPrintTicket2 = true;
    },
    print() {
      this.$htmlToPaper("printMe");
    },
    closePrintDialog() {
      this.dialogPrintTicket = false;
    },
    openPrintMultiple() {
      if (this.selectedAddOns != "") {
        this.dialogPrintTicketMulti = true;
      }
    },
    printMulti() {
      this.$htmlToPaper("printMeMultiTicket");
    },
    closePrintMultiDialog() {
      var inputs = document.getElementsByClassName("myCheck");
      for (var i = 0, l = inputs.length; i < l; ++i) {
        if (inputs[i].checked) {
          inputs[i].checked = false;
        }
      }
      this.selectedAddOns = [];
      this.dialogPrintTicketMulti = false;
    },
    ...mapMutations("$_machine_lottery", [
      "LATEST_FIVE_RESULTS",
      "CURRENT_BETTING",
      "PREVIOUS_BETTING",
      "COUNT_TIME",
    ]),
  },
};
</script>
<style scoped>
.current-betting-tr > td {
  font-size: 0.8rem !important;
  color: black;
  
}

.tb-bet {
  table-layout: auto;
  padding-right:5px;
  width: 100%
}

table {
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
  display: table;
  text-indent: initial;
  border-color: grey;
}
table > tr {
  vertical-align: middle;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.tb-bet td:first-child {
  width: 40px;
}
.tb-bet td,
.left-side > section .recent-bet .tb-bet th {
  outline: none;
  text-align: left;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left:10px;
  padding-right:5px;
}
td {
  display: table-cell;
  vertical-align: inherit;
}
.result-loss {
  color: #8b0000;
}
.result-win {
  color: #00f;
}
.sub-title {
  color: #0c478b !important;
  font-size: 0.85rem !important;
  padding-top: 7px;
  padding-bottom: 3px;
  height: 22px;
  font-weight: 700;
}
.current-betting-lottery-list {
  height: 350px;
  overflow-y: scroll;
}
.previous-betting-lottery-list {
  height: 163px;
  overflow-y: scroll;
}
.current-betting-tr:nth-child(odd) {
  background: #e7e9eb;
}
.print-all {
  color: red !important;
  font-size: 12px !important;
}
.myCheck{
  padding-left:5px;
  margin-left:5px
}
.tb-bet td, .left-side > section .recent-bet .tb-bet th{
  text-align: right;
}
.my-table-view{display: grid;grid-template-columns: repeat(3,1fr);grid-row: minmax(50px, auto); }
.my-td-view{color:black;}
.my-td-view-colspan{grid-column: 1/4; background-color: rgb(238, 255, 255); }


.my-text-g-left{text-align: left;}
.list-result-body ul li{ font-size: 0.8rem !important;}
.detail-resport th{
  background:#0c478b;
  color:white!important;
  font-size:1.1rem!important;
  text-shadow: 1px 2px black!important;
}
.detail-resport tr:nth-child(even) {
  background-color: #a1cafa;
  color: white;
}
.detail-resport tr:nth-child(even):hover {
  background-color: #a1cafa!important;
  color: white;
}
.dialog-title{
  font-size:1.7rem!important;
  text-shadow: 0px 1px black!important;
  color: #0c478b;
  font-weight:900;
  margin-bottom:10px;
  margin-left:0px;
  text-decoration: underline;
}


/**/
.theme--light.v-card{background-color: #1e1e1e !important; color: white;}
.dialog-title{color: white;}
.detail-resport th{background-color: #1e1e1e !important;}
.theme--light.v-data-table{color: white !important; background-color: #1e1e1e !important;}
.detail-resport tr:nth-child(2n){color: white !important; background-color: #1e1e1e !important;}
.detail-resport tbody tr:nth-child(even):hover{
  background-color: rgba(90, 90, 90,0.6) !important;
}
.detail-resport tbody tr:nth-child(odd):hover{
  background-color: rgba(90, 90, 90,0.6) !important;
}

.lose-color {
  color:red
}

@media screen and (max-width: 1263px){
  .list-result-body ul li {
    font-size: .6rem !important;
}

 

}

</style>
