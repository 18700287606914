import Vue from 'vue';
import axios from 'axios';
import { handleError } from './hedleError.js';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
const slotUrl = process.env.VUE_APP_SLOT_URL;

class Request {
	async post({ url, data }) {
		return this._request({ url, method: 'post', data });
	}

	async get({ url, params }) {
		return this._request({ url, method: 'get', params });
	}

	async put({ url, data }) {
		return this._request({ url, method: 'put', data });
	}

	async delete({ url, data }) {
		return this._request({ url, method: 'delete', data });
	}
	//SLOT REQUEST API
	async postSlot({ url, data }) {
		return this._request({ url : slotUrl+url, method: 'post', data });
	}

	async getSlot({ url, params }) {
		return this._request({ url: slotUrl+url, method: 'get', params });
	}

	async putSlot({ url, data }) {
		return this._request({ url: slotUrl+url, method: 'put', data });
	}

	async deleteSlot({ url, data }) {
		return this._request({ url: slotUrl+url, method: 'delete', data });
	}

	async switchVideo({ url, data }) {
		return this._requestChange({ url: url, method: 'get', data });
	}

	async _requestChange({ url, method, params = null, data = null, headers }) {
		if (!headers) {
			headers = {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			};
		}

		const token = Vue.$cookies.get('wa855_token');
		if (token) {
			headers['Authorization'] = token;
		}
		const channelType = Vue.$cookies.get('wa855_channel_type');
		headers['channel-type'] = channelType ? channelType : 1;
		headers['app-id'] = process.env.VUE_APP_ID_ONE;
		try {
			const response = await axios({
				url,
				method,
				headers,
				data,
				params
			});
			return response;
		} catch (error) {
			const errorResponse = Vue.$cookies.get('error');
			if (errorResponse === 'false' || errorResponse === null) {
				Vue.$cookies.set('error', 'true');
				if (
					!error.status ||
					!error.response ||
					(error.response && typeof error.response.status === 'undefined')
				) {
					const status = error.response ? error.response.status : error.response;
					handleError(status);
				} else {
					handleError(error.response.status);
				}
			}
			setTimeout(() => {
				Vue.$cookies.remove('error');
			}, 3000);
			throw error;
		}
	}

	async _request({ url, method, params = null, data = null, headers }) {
		if (!headers) {
			headers = {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			};
		}

		const token = Vue.$cookies.get('wa855_token');
		if (token) {
			headers['Authorization'] = token;
		}
		const channelType = Vue.$cookies.get('wa855_channel_type');
		headers['channel-type'] = channelType ? channelType : 1;
		headers['app-id'] = process.env.VUE_APP_ID;
		try {
			const response = await axios({
				url,
				method,
				headers,
				data,
				params
			});
			return response;
		} catch (error) {
			const errorResponse = Vue.$cookies.get('error');
			if (errorResponse === 'false' || errorResponse === null) {
				Vue.$cookies.set('error', 'true');
				if (
					!error.status ||
					!error.response ||
					(error.response && typeof error.response.status === 'undefined')
				) {
					const status = error.response ? error.response.status : error.response;
					handleError(status);
				} else {
					handleError(error.response.status);
				}
			}
			setTimeout(() => {
				Vue.$cookies.remove('error');
			}, 3000);
			throw error;
		}
	}
}

export default Request;
