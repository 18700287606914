<template>
  <div v-if="passListLotto" class="space-screen">
    <div class="elem-2 row mx-0">
      <div v-for="index in 10" :key="index" class="mx-auto result-loto">
        <div class="sphere2 mx-auto mt-1"
          :class="passListLotto.length == index ? 'animated heartBeat ' + _detectColor(index) : '' + _detectColor(index) ">
          <span class="lotto-style">{{ passListLotto && passListLotto[index - 1] ? passListLotto[index - 1] : '?' }}</span>
        </div>
      </div>
    </div>
    <div class="elem-1 row mx-0">
      <div v-for="index in 10" :key="index" class="mx-auto result-loto">
        <div class="sphere2 mx-auto mt-1" :class="passListLotto.length == index + 10 ? 'animated heartBeat ' + _detectColor(index+10) : '' + _detectColor(index+10) ">
          <span class="lotto-style">{{ passListLotto && passListLotto[index + 9] ?passListLotto[index + 9] : '?'
          }}</span>
        </div>

      </div>
    </div>

  </div>
</template>
<script>

export default {
  props: ["passListLotto"],
  components: {
    // PrintBetting
  },
  methods: {
    _detectColor(index) {
      switch (index) {
        case 0:
          return 'sp-1';
        case 1:
          return 'sp-1';
        case 2:
          return 'sp-2';
        case 3:
          return 'sp-3';
        case 4:
          return 'sp-4';
        case 5:
          return 'sp-5';
        case 6:
          return 'sp-6';
        case 7:
          return 'sp-7';
        case 8:
          return 'sp-8';
        case 9:
          return 'sp-9';
        case 10:
          return 'sp-10';
        case 11:
          return 'sp-11';
        case 12:
          return 'sp-12';
        case 13:
          return 'sp-13';
        case 14:
          return 'sp-14';
        case 15:
          return 'sp-15';
        case 16:
          return 'sp-16';
        case 17:
          return 'sp-17';
        case 18:
          return 'sp-18';
        case 19:
          return 'sp-19';
        case 20:
          return 'sp-20';
        case 22:
          return 'sp-10';
        default:
          return ''
      }
    }
  }
}
</script>
<style scoped>
.result-loto:has(.to-hide) {
  display: none !important
}

.ball-style {
  width: 30px;
  height: 30px;
  border-radius: 50%;

}

.elem-1 {
  width: 100%;
  height: 40px;
  content: "";
  z-index: 1000;
  background-position: 56%, 0, 100% 0;
  margin-top:-45px!important;
  /* background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(219, 173, 20, 0.7), rgba(0, 0, 0, 0.2)); */
  /* background: rgb(0, 0, 0,0.5); */
}

.elem-2 {
  width: 100%;
  height: 90px;
  content: "";
  z-index: 1000;
  background-position: 56%, 0, 100% 0;
  border-style: solid;
  border-width: 2px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(219, 173, 20, 0.6), rgba(0, 0, 0, 0.2));
  -o-border-image: linear-gradient(to right, rgba(219, 173, 20, 0.7), white, rgba(219, 173, 20, 0.7)) 1;
  border-image: linear-gradient(to right, rgba(219, 173, 20, 0.7), white, rgba(219, 173, 20, 0.7)) 1;
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  /* background: rgb(0, 0, 0,0.5); */
}

.card-style {
  width: 28px;
  height: 35px;
  margin-top: 1px;
}

.card-style-rotate {
  width: 28px;
  height: 35px;
  margin-top: 1px;
}

.sp-1 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #deb887, 10px 10px 20px black, inset 0px 0px 10px black;
}

.sp-2 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #8fbc8f, 10px 10px 20px black, inset 0px 0px 10px black;
}

.sp-3 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #cd5c5b, 10px 10px 20px black, inset 0px 0px 10px black;
}

.sp-4 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #20b2aa, 10px 10px 20px black, inset 0px 0px 10px black;
}

.sp-5 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #cd853f, 10px 10px 20px black, inset 0px 0px 10px black;
}

.sp-6 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px green, 10px 10px 20px black, inset 0px 0px 10px black;
}

.sp-7 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #eb3867, 10px 10px 20px black, inset 0px 0px 10px black;
}

.sp-8 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #72286f, 10px 10px 20px black, inset 0px 0px 10px black;
}

.sp-9 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #ddb611, 10px 10px 20px black, inset 0px 0px 10px black;
}

.sp-10 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #b8b460, 10px 10px 20px black, inset 0px 0px 10px black;
}

.sp-11 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #2e8b58, 10px 10px 20px black, inset 0px 0px 10px black;
}
.sp-12 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #f4a461, 10px 10px 20px black, inset 0px 0px 10px black;
}
.sp-12 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #695acd, 10px 10px 20px black, inset 0px 0px 10px black;
}

.sp-12 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #008080, 10px 10px 20px black, inset 0px 0px 10px black;
}
.sp-13 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #ff6348, 10px 10px 20px black, inset 0px 0px 10px black;
}
.sp-14 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #8b4512, 10px 10px 20px black, inset 0px 0px 10px black;
}
.sp-15 {
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #bc8f8f, 10px 10px 20px black, inset 0px 0px 10px black;
}
.sp-16{
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #808000, 10px 10px 20px black, inset 0px 0px 10px black;
}
.sp-17{
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #778899, 10px 10px 20px black, inset 0px 0px 10px black;
}
.sp-18{
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #1d90ff, 10px 10px 20px black, inset 0px 0px 10px black;
}
.sp-19{
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #609ea0, 10px 10px 20px black, inset 0px 0px 10px black;
}
.sp-20{
  position: relative;
  height: 30px;
  width: 30px;
  background-color: red;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 10px;
  box-shadow: inset -10px -10px 100px #dc143c, 10px 10px 20px black, inset 0px 0px 10px black;
}



.sphere::after,
.sphere2::after {
  background-color: rgba(255, 255, 255, 1);
  content: '';
  height: 40%;
  width: 10%;
  position: absolute;
  top: 8%;
  left: 14%;
  border-radius: 50%;
  transform: rotate(45deg);
}

.sphere2 {
  height: 32px;
  width: 32px;
  position: relative;
  background-color: yellow;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;

}

.sphere2::after {
  background-color: rgba(255, 255, 255, 0.5);
  content: '';
  position: absolute;
  border-radius: 50%;
  transform: rotate(45deg);
}
.lotto-style{
  font-size:22px; font-weight:900; margin-top:-10px; text-shadow: 2px 0 black;     margin-left: -2px;
}
@media (min-width: 960px) {
  .space-screen {
    margin:0px;
    margin-top: 26px;
  }
}

@media (max-width: 960px) {
  .space-screen {
    margin:0px;
    margin-top: 13px;
  }
}
</style>
