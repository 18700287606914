<template>
    <div class="px-2 max-label row ma-0" style="justify-content: space-between;">
        <v-spacer></v-spacer>
        <p v-if="!passWeight" class="text-right" >
            {{ `${$t('title.min')}: ${amountMin.toLocaleString("en")}  
             | ${$t('title.max')}: ${amountMax.toLocaleString("en")}` }}
        </p>
        <p v-else class="text-right">
            {{ `${$t('title.min')}: ${passWeight.amount_min.toLocaleString("en")}  
             | ${$t('title.max')}: ${passWeight.amount_max.toLocaleString("en")}` }}
        </p>
    </div>
</template>

<script>
import "@/assets/css/homepage.css";
import { mapGetters } from "vuex";
import Mixin from "../mixin/index";
export default {
    props: ['passWeight'],
    data() {
        return {
            mixins: [Mixin],
        }
    },
    computed: {
        // ...mapGetters("$_modules", {
        //     userInfo: "getUserInfo",
        // }),
        ...mapGetters("$_dashboard_live", {
            amountMin: "getAmountMin",
            amountMax: "getAmountMax",
        }),
    },
}
</script>
<style scoped>
.max-label{
    width:100%; 
    height:30px; 
    margin-bottom:-9px;
}
.format-currency{
    font-size:1.2rem;
    margin-top : -2px;
    font-weight: 500;
    color:gold;
    margin-left:1px;
}
</style>