<template>
  <div class="trends limit-on-tab">
    <div>
      <!-- <div class="trend-legend  gp-panel-bg  d-flex  justify-content-between align-items-center" style="background-color: #ffffff !important;">
          <div class="bad-cont d-inline-flex align-items-center mr-1"><span
              class="count rounded-circle trend-wala">{{ resultObj.summary["NUMBER-1"] }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk1") }}</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center mr-1"><span
              class="count rounded-circle trend-wala">{{ resultObj.summary["NUMBER-2"] }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk2") }}</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center mr-1"><span
              class="count rounded-circle trend-wala">{{ resultObj.summary["NUMBER-3"] }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk3") }}</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center "><span
              class="count rounded-circle trend-wala">{{ resultObj.summary["NUMBER-4"] }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk4") }}</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center "><span
              class="count rounded-circle trend-wala">{{ resultObj.summary["NUMBER-5"] }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk5") }}</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center "><span
              class="count rounded-circle trend-wala">{{ resultObj.summary["NUMBER-6"] }}</span>
            <p class="mb-0 count-label">{{ $t("menu.kk6") }}</p>
          </div>
          <div class="bad-cont d-inline-flex align-items-center "><span
              class="count rounded-circle trend-wala">{{ resultObj.summary.CANCEL }}</span>
            <p class="mb-0 count-label">Cancelled</p>
          </div>
        </div> -->
      <div class="px-3 pb-4 gp-panel-bg rounded-bottom landscape-trend"
        style="position: relative; background-color: #ffffff !important;">
        <div class="table-responsive pb-3 gp-scroll">
          <table class="kk-table" style="height:167px">
            <tbody>
              <tr class="han2-tr-result">
                <td>
                  <div>
                    <div style="
                  width:41px; height:33.5px
                  margin-right: -1px;
                  text-align: center;
                  margin-bottom: 0px; color: black; 
                ">
                      <b style="font-weight:700; font-size:.9rem;"></b>
                    </div>
                    <div>
                      <p style="color:black; font-weight:700; font-size:1.3rem; padding-top:9px"> P1</p>
                      <p style="color:black; font-weight:700; font-size:1.3rem; padding-top:9px"> P2</p>
                      <p style="color:black; font-weight:700; font-size:1.3rem; padding-top:9px"> P3</p>
                    </div>
                  </div>
                </td>
                <td v-for="col in cols" :key="col">
                  <div>
                    <div
                      v-for="(result, i) in (resultObj.results ? resultObj.results.slice().reverse() : resultObj.results)"
                      :key="i" class="text-center"
                      style="width:40px;max-width:40px;margin-left: auto; margin-right: auto; text-align: center;">
                      <div v-if="(col - 1) == i">
                        <div width="50" style="
                          margin-right: -1px;
                          border: 1px solid #535151;
                          text-align: center;
                          margin-bottom: 0px; color: black; 
                          background-color: rgb(113 184 255);">
                          <b style="font-weight:700; font-size:.9rem">#{{ result.fight_no }}</b>
                        </div>
                        <p> <span :class="_getResultClass(result.result1_name).c">{{ i == 0
                          ? _getResultClass(result.result1_name).f : _getResultClass(result.result1_name).n }}</span></p>
                        <p> <span :class="_getResultClass(result.result2_name).c">{{ i == 0
                          ? _getResultClass(result.result2_name).f : _getResultClass(result.result2_name).n }}</span></p>
                        <p><span :class="_getResultClass(result.result3_name).c">{{ i == 0
                          ? _getResultClass(result.result3_name).f : _getResultClass(result.result3_name).n }}</span></p>
                        </div>
                    </div>
                    <div v-if="col>resultObj.results.length">
                      <div  style="
                      height:34px;
                      width:41px;
                      margin-right: -1px;
                      border: 0.5px solid #535151;
                      text-align: center;
                      margin-bottom: 0px; color: black; 
                      background-color: rgb(113 184 255);">
                      
                    </div>
                    <div>
                      <p style="color:black; font-weight:700; font-size:1.3rem; padding-top:9px"> </p>
                      <p style="color:black; font-weight:700; font-size:1.3rem; padding-top:9px"> </p>
                      <p style="color:black; font-weight:700; font-size:1.3rem; padding-top:9px"> </p>
                    </div>
                  
                    </div>

                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
export default {
  props: ["rows", "cols"],

  computed: {
    ...mapGetters("$_dashboard", {
      resultObj: "getResult",
      channel: "getChannel",
    }),
    orderResult() {
      return this.getListResults();
    },
    number(bet) {
      return this.getNumber(bet);
    }
  },
  methods: {
    _getResultClass(x) {
      switch (x) {
        case 'Banker 1':
          return { c: 'han2-r-td-banker', n: 'B', f: 'B', cf: 'han2-r-td-banker-f' }
        case 'Banker 2':
          return { c: 'han2-r-td-banker', n: 'B', f: 'B', cf: 'han2-r-td-banker-f' }
        case 'Banker 3':
          return { c: 'han2-r-td-banker', n: 'B', f: 'B', cf: 'han2-r-td-banker-f' }
        case 'Player 1':
          return { c: 'han2-r-td-player', n: 'P', f: 'P', cf: 'han2-r-td-player-f' }
        case 'Player 2':
          return { c: 'han2-r-td-player', n: 'P', f: 'P', cf: 'han2-r-td-player-f' }
        case 'Player 3':
          return { c: 'han2-r-td-player', n: 'P', f: 'P', cf: 'han2-r-td-player-f' }
        case 'CANCEL':
          return { c: 'han2-r-td-cancel', n: 'C', f: 'C', cf: 'han2-r-td-cancel-f' }
        case 'Tie':
          return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
          case 'Tie 1':
          return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
          case 'Tie 2':
          return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
          case 'Tie 3':
          return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
        default:
          return ''
      }
    }
  },
};
</script>
  
<style scoped>
.kk-table td {
  width: 39px;
  height: 163px;
  line-height: 30px;
  font-size: 10px;
  text-align: center;
  /*border: 1px solid #c5c5c5;*/
  padding: 0px;
}

.kk-table td>div {
  width: 40px;
}

table tr td {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  /*border: 1px solid white;*/
}

table {
  border-collapse: collapse;
  width: 100% !important;
  max-width: 100% !important;
  overflow-y: scroll !important;
  overflow-x: scroll !important;
}

.han2-tr-result td p {
  border: 1px solid #9e9e9e;
  margin-bottom: 0px;
  margin-right: -1px;
  /*border-radius: 25px;*/
  padding: 2px;
  height: 41px;
  width: 41px;
}

.han2-tr-result td p span {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;

  font-weight: 900;
  padding-top: 2px;
}

.han2-r-td-tie {
  background-color: #4caf50;
  font-size: 1.2rem;
}

.han2-r-td-cancel {
  background-color: #9e9e9e;
  font-size: 1.2rem;
}

.han2-r-td-player {
  background-color: #0b4592;
  font-size: 1.2rem;
}

.han2-r-td-banker {
  background-color: #b31013;
  font-size: 1.2rem;
}

.han2-r-td-banker-f {
  color: #b31013;
  font-size: 1.5rem;
}

.han2-r-td-player-f {
  color: #0b4592;
  font-size: 1.5rem;
}

.han2-r-td-cancel-f {
  color: #9e9e9e;
  font-size: 1.5rem;
}</style>