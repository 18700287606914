
<template>
    <div style="background:#386fae">
      <a v-for="(i, index) in userInfo.slot_channel" :key="i" @click="getToRoute(i, _findChannelType(i))" >
        <span :class=" $route.name == i ? 'span-active' : ''" class="span-btn" style="width:33.3%" :style="index == 0 || index == 2  ?'border-right:1px solid white; border-left:1px solid white;':''">
          <img :src="_calculateLogo(i)" alt="" class="tab-logo">
        </span>
      </a>

    </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
export default {
  computed: {
        ...mapGetters("$_modules", {
            userInfo: "getUserInfo",
        }),
    },
  methods: {
    _findChannelType(url){
      switch(url){
        case 'LOTTO'
          : return 90;
        case 'YUKI'
          : return 91;
        case 'LOTTERY'
          : return 92;
        // case 'tiger-dragon'
        //   : return 93;
        // case 'klaklouk'
        //   : return 94;
        // case 'baccarat'
        //   : return 95;
      }
    },
    _calculateLogo(url){
      switch(url){
        case 'LOTTO'
          : return require('../../../public/images/machine_game_logo/x-lotto99-icon.png');
        case 'YUKI'
          : return require('../../../public/images/machine_game_logo/x-yuki-icon.png');
        case 'LOTTERY'
          : return require('../../../public/images/machine_game_logo/x-lotto78-icon.png');
        // case 'tiger-dragon'
        //   : return require('../../../public/images/machine_game_logo/x-dragon-tiger-icon.png');
        // case 'klaklouk'
        //   : return require('../../../public/images/machine_game_logo/x-klaklok-icon.png');
        // case 'baccarat'
        //   : return require('../../../public/images/machine_game_logo/x-klaklok-icon.png');
      }

    },
    getToRoute(path, channel_type) {
      this.$cookies.set("wa855_channel_type", channel_type);
      this.UPDATE_CHANNEL_ID(channel_type);
      this.$router.push({ path });
    },
    ...mapMutations("$_modules", [
            "UPDATE_CHANNEL_ID"
    ]),
  },
};
</script>
<style>
  .tab-logo{
    height:40px;
    width:38px;
    margin-top:-3px
  }
</style>