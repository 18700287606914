<template>
  <v-row style="margin-top: 5px !important" class="pt-2 pb-3">
    <v-col md="12" lg="12" sm="12" style="padding: 0 10px 0 10px">
      <!-- <p style="color:black">{{ getSelectedNumber }}</p> -->
      <div class="plus-middle-bottom-box--x row px-0 mx-auto justify-center">
        <div
          class="lus-b-col--x pt-0 pb-1" 
          v-for="(number, index) in getNumber"
          :key="index"
        >
          <v-btn
            @click="selectNumber(index, number)"
            v-bind:class="{
              activex: getSelectedNumber[index],
              noClick: clickable,
            }"
            :id="classes"
            @mouseover="mouseOver"
            style="
              font-size: 20px;
              font-weight: bold;
              width: 45px ;
              height: 45px ;
       
            "
            fab
            dark
            small
            :color="
              selectedListBettingNumber.length > 0 &&
              selectedListBettingNumber.includes(number)
                ? 'red'
                : '#013062'
            "
          >
            {{ number ? number : 0 }}
          </v-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      clickable: false,
      randomNumberLabel: [],
      classes: "",
      bettingArrayNumber: [],
      checkPost: ["A"],
    };
  },
  computed: {
    ...mapGetters("$_machine_lottery", {
      getLotteryChannel: "getLotteryChannel",
      getSelectedNumber: "getSelectedNumber",
      getNumber: "getNumbers",
      getBettingType: "getSelectedBettingType",
      selectedListBettingNumber: "getSelectedListBettingNumber",
    }),
  },
  beforeMount() {
    // set all values to false
    this.getNumber.forEach((item, index) =>
      this.$set(this.getSelectedNumber, index, false)
    );
  },
  methods: {
    mouseOver() {
      this.classes = "btnHover";
    },
    // ****** Type: 1 normal, 2 go with tail, 3 go with head ******
    async selectNumber(index, number) {
      var sdigit;
      let sdigitInt;
      if (this.getBettingType == 1) {
        this.$set(
          this.getSelectedNumber,
          index,
          !this.getSelectedNumber[index]
        );
        if (this.getSelectedNumber.length >= 0) {
          for (let i = 0; i < this.getNumber.length; i++) {
            const element = this.getSelectedNumber[i];
            if (element == true) {
              //when button is clicked
              let intElement = parseFloat(number);
              if (!this.selectedListBettingNumber.includes(intElement)) {
                this.UPDATED_SELECT_LIST_BETTING(intElement);
              }
              this.calculateTotal();
            } else if (this.getSelectedNumber[index] == false) {
              this.calculateTotal();
              this.UNSELECT_LIST_BETTING(number);
            }
          }
        }
      } else if (this.getBettingType == 2) {
        let xcludeArr = [];
        if (this.getSelectedNumber[index] == false) {
          for (let i = 0; i < this.getNumber.length; i++) {
            const element = this.getNumber[i];
            let intElement = parseFloat(element);
            if (number.toString().length > 1) {
              sdigit = number.toString()[1];
              sdigitInt = parseFloat(sdigit);
              if (intElement % 10 == sdigitInt) {
                if (!this.selectedListBettingNumber.includes(intElement)) {
                  this.UPDATED_SELECT_LIST_BETTING(intElement);
                  this.getSelectedNumber[intElement] = true;
                }
              }
            }
          }
          if (xcludeArr.length > 0) {
            this.calculateTotal();
          }
        } else {
          for (let i = 0; i < this.getNumber.length; i++) {
            const element = this.getNumber[i];
            let intElement = parseFloat(element);
            if (number.toString().length > 1) {
              sdigit = number.toString()[1];
              sdigitInt = parseFloat(sdigit);
              //console.log('sdigit:',sdigit);

              if (intElement % 10 == sdigitInt) {
                if (!xcludeArr.includes(intElement)) {
                  xcludeArr.push(intElement);
                  this.UNSELECT_SPECIFIC_BETTING(intElement);
                }
              }
            }
          }
        }
        if (xcludeArr.length > 0) {
          let data = {
            number: number,
            xcludeArr: xcludeArr,
          };
          this.FILTER_UNSELECTED(data);
          // this.selectedListBettingNumber = this.selectedListBettingNumber.filter((number) => {
          //   return !xcludeArr.includes(number);
          // });
        }
        this.calculateTotal();
      } else if (this.getBettingType == 3) {
        //ous kbal
        let xcludeArr = [];
        //console.log("getSelectedNumber",this.getSelectedNumber[index]);
        if (this.getSelectedNumber[index] == false) {
          for (let i = 0; i < this.getNumber.length; i++) {
            const element = this.getNumber[i];
            //console.log('element: ' + element);
            let intElement2 = parseFloat(element);
            let intElementString = element.toString()[0];
            let intElement = parseFloat(intElementString);
            if (number.toString().length > 1) {
              sdigit = number.toString()[0];
              sdigitInt = parseFloat(sdigit);
              //console.log('sdigit:',sdigit);

              if (intElement == sdigitInt) {
                if (!this.selectedListBettingNumber.includes(intElement2)) {
                  this.UPDATED_SELECT_LIST_BETTING(intElement2);
                  this.getSelectedNumber[intElement2] = true;
                } else {
                  console.log("1");
                }
              }
            }
          }
          this.calculateTotal();
        } else {
          //his.getSelectedNumber[index] == true
          for (let i = 0; i < this.getNumber.length; i++) {
            const element = this.getNumber[i];
            // console.log("element-----: " + element);
            let intElement2 = parseFloat(element);
            let intElementString = element.toString()[0];
            let intElement = parseFloat(intElementString);
            if (number.toString().length > 1) {
              sdigit = number.toString()[0];
              sdigitInt = parseFloat(sdigit);
              //console.log('sdigit:',sdigit);

              if (sdigitInt == 0) {
                for (let j = 0; j < 10; j++) {
                  this.getSelectedNumber[j] = false;
                  var indexOfNum = this.selectedListBettingNumber.indexOf(j);
                  this.selectedListBettingNumber.splice(indexOfNum, 1);
                }
                if (!xcludeArr.includes(intElement)) {
                  xcludeArr.push(intElement2);
                }
              }

              if (intElement == sdigitInt) {
                if (!xcludeArr.includes(intElement)) {
                  xcludeArr.push(intElement2);
                  this.getSelectedNumber[intElement2] = false;
                }
              }
            }
            this.calculateTotal();
          }
        }
        if (xcludeArr.length > 0) {
          let data = {
            number: number,
            xcludeArr: xcludeArr,
          };
          this.FILTER_UNSELECTED(data);
        }

        this.calculateTotal();
      }
    },
    calculateTotal() {
      if (this.getAmount > 0) {
        // if(this.toggle_none ==0) {
        if (this.bettingArrayNumber.length > 0) {
          const data = {
            numberOfBetting: this.bettingArrayNumber.length,
            numberOfCheckPost: this.checkPost.length,
          };
          this.UPDATE_TOTAL_BETTING_AMOUNT(data);
          // this.totalAmount = this.getAmount*this.bettingArrayNumber.length*this.checkPost.length;
        } else {
          //this.totalAmount = this.amount*this.checkPost.length;
        }

        // } else {
        //   this.totalAmount = this.amount*10*this.checkPost.length;
        // }
      } else {
        this.totalAmount = 0;
      }
    },
    ...mapMutations("$_machine_lottery", [
      "UPDATED_SELECT_LIST_BETTING",
      "UNSELECT_LIST_BETTING",
      "UNSELECT_SPECIFIC_BETTING",
      "FILTER_UNSELECTED",
    ]),
  },
};
</script>

<style scoped>
.plus-middle-bottom-box--x{
  display: grid;grid-template-columns: auto auto auto auto auto auto auto auto auto auto;gap: 0px; padding-bottom: 6px;
}
.lus-b-col--x{margin-right: 12px;}

@media screen and (max-width: 1263px){
  .lus-b-col--x button{font-size: 14px !important; width: 30px !important; height: 30px !important;}
}
@media screen and (max-width: 959px){
  .lus-b-col--x button{font-size: 20px !important; width: 45px !important; height: 45px !important;}
}
@media screen and (max-width: 600px){
  .lus-b-col--x button{font-size: 14px !important; width: 30px !important; height: 30px !important;}
  
}
@media screen and (max-width: 450px){
  .lus-b-col--x button{font-size: 12px !important; width: 25px !important; height: 25px !important;}
}
@media screen and (max-width: 414px){
  .lus-b-col--x button{font-size: 12px !important; width: 25px !important; height: 25px !important;}
}
</style>