import { render, staticRenderFns } from "./videoLiveForDesktop.vue?vue&type=template&id=ab94e332&scoped=true&"
import script from "./videoLiveForDesktop.vue?vue&type=script&lang=js&"
export * from "./videoLiveForDesktop.vue?vue&type=script&lang=js&"
import style0 from "./videoLiveForDesktop.vue?vue&type=style&index=0&id=ab94e332&prod&lang=css&"
import style1 from "./videoLiveForDesktop.vue?vue&type=style&index=1&id=ab94e332&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab94e332",
  null
  
)

export default component.exports