<template>
  <div class="result-img w-100 row space-screen">
    <div class="elem-1" style="z-index:1000;">
      <div class="row w-100 m-0">
        <div class="text-left w-50 pl-2">
          <img :src="`/images/cards/${passDragonCard}`" class="card-style" />
        </div>
        <div class="text-right w-50 pr-5 pt-2">
          <span style="font-size:20px; font-weight: 900; color:#f34141; text-shadow: 1px 1px black"> Dragon </span>
        </div>
      </div>
    </div>

    <div class="elem-2" style="z-index:1000; ">
      <div class="row w-100 m-0">
        <div class="text-left w-50 pl-5 pt-2">
          <span style="font-size:20px; font-weight: 900; color:#167df1; text-shadow: 1px 1px black;"> Tiger </span>
        </div>
        <div class="text-right w-50 pr-2">
          <img :src="`/images/cards/${passTigerCard}`" class="card-style" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapMutations } from "vuex";
export default {
  props: ["passDragonCard", "passTigerCard"],
  components: {
    // PrintBetting
  },
  ...mapMutations("$_dashboard", [
    "UPDATE_DRAGON_CARD",
    "UPDATE_TIGER_CARD",
  ]),
}
</script>
<style scoped>
.elem-1 {
  /* background: rgb(243, 65, 65); */
  width: 50%;
  height: 52px;
  content: "";
  z-index: 100;
    background: linear-gradient(to right, rgba(243, 65, 65), rgba(0, 0, 0, 0.7));
    background-position: 56%, 0, 100% 0;
    border-style: solid;
    border-width: 2px;
    -o-border-image: linear-gradient(100deg, rgba(243, 65, 65), white) 1;
    border-image: linear-gradient(100deg, rgba(243, 65, 65), white) 1;

    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;

}

.elem-2 {

  width: 50%;
  height: 52px;
  content: "";
  z-index: 1000;
  background-position: 56%, 0, 100% 0;
  border-style: solid;
  border-width: 2px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(22, 125, 241, 1));
    -o-border-image: linear-gradient(100deg, white, rgba(22, 125, 241)) 1;
    border-image: linear-gradient(100deg, white, rgba(22, 125, 241)) 1;
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  /* background: rgb(0, 0, 0,0.5); */

}

.card-style {
  width: 28px;
  height: 35px;
  margin-top: 8px;
}

@media (min-width: 960px) {
  .space-screen {
    margin:0px;
    margin-top: 12px;
  }
}

@media (max-width: 960px) {
  .space-screen {
    margin:0px;
    margin-top: 1px;
  }
}
</style>
