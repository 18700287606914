<template>
    <div>
    <v-navigation-drawer v-model="drawer" right fixed temporary>
            <SideBar :passDrawer="drawer" :passUserInfo="passUserInfo" v-on:invokeLogout="handleLogout"></SideBar>
        </v-navigation-drawer>
        </div>
</template>
<script>
import "@/assets/css/header.css";
import SideBar from './SideBar.vue';
export default {
    components: { SideBar },
    props: ['passUserInfo'],
    data: () => ({
        drawer: false,
        websiteLogo:'//static3.goperya.com/img/logo-3.png?v=10.60'
    }),
    methods:{
        handleDrawer(getDrawer){
            this.drawer = getDrawer;
        },
        handleLogout(){
            this.$emit("invokeLogout");
        }
    }
}
</script>