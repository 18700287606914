export default {
    permission_list: 'Permission List',
    role_list: 'Role List',
    user_list: 'User List',
    menu_list: 'Menu List',
    create_permission: 'Create Permission',
    update_permission: 'Update Permission',
    create_role: 'Create Role',
    update_role: 'Update Role',
    create_user: 'Create User',
    update_user: 'Update User',
    create_menu: 'Create Menu',
    update_menu: 'Update Menu',
    change_password_user: 'Change Password User',
    welcome: 'hoan nghênh:',
    account_balance: 'số dư tài khoản',
    user_role: 'vai trò người dùng',
    No:'Số',
    no:'Số',
    your_balance:'số dư của bạn',
    home:'Trang Chủ',
    sbc:'SBC',
    status:'trạng thái',
    time:'thời gian',
    result:'kết quả',
    five_k:'1.5 $',
    ten_k:'2.5 $',
    twenty_k:'5 $',
    five_k1:'12.5 $',
    ten_100k:'25 $',
    twenty_200k:'50 $',
    five_500k:'125 $',
    one_1m:'1M $',
    payout:'thanh toán',
    current_betting:'cá cược hiện tại',
    thin:"Under",
    thick:"Over",
    red:'Red',
    blue:'Blue',
    today_report:'báo cáo hôm nay',
    fight:'trận đánh',
    type:'kiểu',
    amount:'số lượng',
    total_amount: 'Tổng số tiền',
    action:'hoạt động',
    my_profile:'thông tin của tôi',
    persional_info:'thông tin cá nhân',
    change_password:'đổi mật khẩu',
    result_betting:'cá cược kết quả',
    result_win:'kết quả thắng',
    report:'bản báo cáo',
    meron:'màu đỏ',
    wala:'màu xanh dương',
    tie:'cà vạt',
    cancel:'sự hủy bỏ',
    login:'đăng nhập',
    deposit:'đặt cọc',
    withdraw:'rút',
    betting:'cá cược',
    all_betting:'cá cược(All)',
    channel1:'kênh 1',
    channel2:'kênh 2',
    betting1:'BET',
    /*betLimit:'Giới hạn đặt cược',*/
    /*pleaseBet:'hãy đặt cược',*/
    channel3:'kênh 3',
    channel4:'kênh 4',
    min:'Ít nhất',
    fight_sign:"#",
    max:'Nhiều nhất',
    bet_between : 'phạm vi',
    bet_small_large: 'bé nhỏ / to lớn',
    return:"Return",
    /*result_year: "Result Year",*/
    /*result_small_big: "Result Small/Big",*/
    /*result_range: "Result Range",*/
    /*odd_even:'Odd/Even',
    blue_red:'Blue/Red',
    no_data: "No data ...",
    total_cast:"Tổng số diễn viên",
    total_amount_win:"Tổng số tiền trả nợ",*/

    all_statement:'Báo cáo chung',
    start_date:'Thơi gian bắt đầu',
    end_date:'Thời gian kết thúc',
    date_time:'Ngày giờ',
    cast:'dàn diễn viên',
    amount_win:'Số tiền thắng',
    total_win_lose:'Tổng thắng / thua',


    game_no:"Số trò chơi",
    betLimit:'Giới hạn cược',
    total_betting_an_invoice:'Tổng số trong một hoa đơn',
    yuki_wipe : "Chuột",
    yuki_street : "Bò",
    yuki_karl : "Hổ",
    yuki_thao : "Thỏ",
    yuki_factory : "Rồng",
    yuki_net : "Snake",
    yuki_thin : "Xỉu",
    yuki_thick : "Tài",
    yuki_mimi : "Ngựa",
    yuki_mom : "Dê",
    yuki_vk : "Khỉ",
    yuki_roka : "Gà",
    yuki_kor : "Lợn",
    yuki_odd : "Lẻ",
    yuki_pair : "Đôi",
    yuki_red : "Đỏ",
    yuki_blue : "Xanh",
    yuki_dog : "Chó",
    yuki_null : "null",
    outstanding:"Đang cược",
    lo_current_betting:"Cược trước",
    lo_previous_betting:"Cược sau",
    ticket:'Vé',
    invoice_detail: "Chi tiết hoa đơn",
    game_id: "Game ID",
    result_year: "Kết quả của năm",
    result_small_big: "Kết quả Xỉu/ Lẻ",
    result_range: "Kết quả phạm vi",
    odd_even:'Lẻ/Chẵn',
    blue_red:'Xanh/Đỏ',
    no_data: "Không dữ liệu",
    total_betting_a_game:'Tổng trong một trò chơi',
    pleaseBet:'Vui lòng cược',
    submit_betting:"Chấp nhận",
    bet_type:"Loại cược",

    yuki_bet_amount:"Vui lòng nhập số tiền cược của bạn",
    yuki_enough_balance:"Không đủ số dư",
    yuki_bet_limit:"Số tiền đặt cược phải giữa giới hạn đặt cược",
    yuki_select_your_bet:"Vui lòng chọn cược của bạn",

    normal_betting:"Bình thường",
    go_with_tail:"Cược đuôi",
    go_with_head:"Cược đầu",
    all_post:"Tấc cả",
    post_channel:"Đài",
    lottery_result:"Kết quả số đề",
    game_close:"Đóng cược",

    total_amount_win:"Tổng số tiền hoàn trả",
    yuki_war:'War',
    bet_total:"Tổng số tiền",
    total_cast:"Total Cast",


    result_number:"Kết quả số",
    x_win:"Thắng",
    x_lose:"Thua",

};
