<template>
    <div class="result-img w-100 row space-screen">
      <div class="elem-2" 
          style="z-index:1000; ">
          <div class="row w-100 m-0 ">
        
          <div class="text-center w-100">
            <img :src="passListAbong ? `${_detectImg(passListAbong[0])} ` : '?'" class="card-style-rotate" />
          </div>
        </div>
        </div>
      </div>
</template>
<script>

// import PrintBetting from "./components/PrintBetting";
export default {
    props: ["passListAbong"],
    components: {
        // PrintBetting
    },

    methods:{
      _detectImg(index) {
            switch (index) {
                case '1':
                    return '/images/6number/1.png';
                case '2':
                    return '/images/6number/2.png';
                case '3':
                    return '/images/6number/3.png';
                case '4':
                    return '/images/6number/4.png';
                case '5':
                    return '/images/6number/5.png';
                case '6':
                    return '/images/6number/6.png';
                default:
                    return ''
            }
        },
    }
}
</script>
<style scoped>
.elem-2{
  width:100%;
  height:50px;
  content: "";
  z-index: 1000;
  background-position: 56%, 0, 100% 0;
    /* border-style: solid;
    border-width: 2px; */
  background: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(219, 173, 20, 0.4), rgba(0, 0, 0, 0.2));
  /* -o-border-image: linear-gradient(100deg, white, rgba(22, 125, 241)) 1;
    border-image: linear-gradient(100deg, white, rgba(22, 125, 241)) 1; */
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    /* background: rgb(0, 0, 0,0.5); */
}

  .card-style-rotate{
    width: 45px;
    height: 41px;
    margin-top:5px;
    background: white;
    border-radius: 5px;
  }
  
@media (min-width: 960px) {
  .space-screen {
    margin:0px;
    margin-top: 12px;
  }
}

@media (max-width: 960px) {
  .space-screen {
    margin:0px;
    margin-top: 1px;
  }
}
</style>
