<template>       
  <div>
    <form-login></form-login>
  </div>
</template>

<script>
import FormLogin from "./components/Login";

export default {
  components: {
    FormLogin,
  },
};
</script>