var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-bet-sec pt-0 px-0 pb-2 px-md-0"},[_c('ChannelInfo',{attrs:{"passChannel":_vm.passChannel}}),_c('div',[_c('div',{staticClass:"g-summary"},[_c('div',{staticClass:"sides-wrapper gp-panel-bg rounded"},[_c('div',{staticClass:"d-flex sides bet-btn-container"},[(_vm.meronButton )?_c('div',{staticClass:"side-section meron-section pb-1 side-1 position-relative",class:[
              _vm.selectedBtnClassMeron ? 'on-active-btn' : '',
              _vm._findButtonSection(_vm.passSelectedChannel, 'left'),
            ],on:{"click":function($event){return _vm._allowBtnBet('selectedMeron', _vm.meronButton)}}},[_c('div',{staticClass:"pad-cock-btn",style:(_vm.getSwitchChannelLoading  ? 'opacity:.1;' : '')},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(_vm._displayMoney( _vm.userInfo.currency_type, _vm.passSelectedChannel, _vm.passCurrentBetting, "left" ))+" ")]),_c('div',{staticClass:"odds",style:(_vm.getSwitchChannelLoading  ? 'opacity:.1;' : '')},[_vm._v(" "+_vm._s(_vm._displayPayout(_vm.passSelectedChannel, "left"))+" ")]),_c('div',{staticClass:"side-title pt-1",style:(_vm.getSwitchChannelLoading  ? 'opacity:.1;' : '')},[_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.meronButton ? _vm.$t( _vm.sameGameFindName(_vm.passSelectedChannel) + _vm.meronButton.name ) : "")+" ")])])]),(_vm.getSwitchChannelLoading)?_c('img',{staticClass:"rolling-loading-img",attrs:{"src":require("../../../../assets/images/loading_rolling.gif")}}):_vm._e()]):_vm._e(),(_vm.tieButton)?_c('div',{staticClass:"side-section-tie pb-1 mx-2 side-1 position-relative",class:[
              _vm.selectedBtnClassTie ? 'on-active-btn' : '',
              _vm._findButtonSection(_vm.passSelectedChannel, 'middle'),
            ],on:{"click":function($event){return _vm._allowBtnBet('selectedTie')}}},[_c('div',{staticClass:"pad-cock-btn",style:(_vm.getSwitchChannelLoading  ? 'opacity:.1;' : '')},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm._displayMoney( _vm.userInfo.currency_type, _vm.passSelectedChannel, _vm.passCurrentBetting, "middle" )))]),_c('div',{staticClass:"odds",style:(_vm.getSwitchChannelLoading  ? 'opacity:.1;' : '')},[_vm._v(" "+_vm._s(_vm.passWeight ? _vm._displayPayout(_vm.passSelectedChannel, "middle") : "x.xx")+" ")]),_c('div',{staticClass:"side-title pt-1",style:(_vm.getSwitchChannelLoading  ? 'opacity:.1;' : '')},[_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.$t(_vm.sameGameFindName(_vm.passSelectedChannel) + "draw"))+" ")])])]),(_vm.getSwitchChannelLoading)?_c('img',{staticClass:"rolling-loading-img",attrs:{"src":require("../../../../assets/images/loading_rolling.gif")}}):_vm._e()]):_vm._e(),(_vm.walaButton)?_c('div',{staticClass:"side-section pb-1 side-1 position-relative",class:[
              _vm.selectedBtnClassWala ? 'on-active-btn' : '',
              _vm._findButtonSection(_vm.passSelectedChannel, 'right'),
            ],on:{"click":function($event){return _vm._allowBtnBet('selectedWala')}}},[_c('div',{staticClass:"pad-cock-btn",style:(_vm.getSwitchChannelLoading  ? 'opacity:.1;' : '')},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm._displayMoney( _vm.userInfo.currency_type, _vm.passSelectedChannel, _vm.passCurrentBetting, "right" )))]),_c('div',{staticClass:"odds",style:(_vm.getSwitchChannelLoading  ? 'opacity:.1;' : '')},[_vm._v(" "+_vm._s(_vm._displayPayout(_vm.passSelectedChannel, "right"))+" ")]),_c('div',{staticClass:"side-title pt-1",style:(_vm.getSwitchChannelLoading  ? 'opacity:.1;' : '')},[_c('div',{staticClass:"truncate"},[_vm._v(" "+_vm._s(_vm.walaButton ? _vm.$t( _vm.sameGameFindName(_vm.passSelectedChannel) + _vm.walaButton.name ) : "")+" ")])])]),(_vm.getSwitchChannelLoading)?_c('img',{staticClass:"rolling-loading-img",attrs:{"src":require("../../../../assets/images/loading_rolling.gif")}}):_vm._e()]):_vm._e()])])]),_c('CoinButton',{attrs:{"passGetButton":_vm.passGetButton,"passChannel":_vm.passChannel,"passPlaceCoin":_vm.bettingMoney}}),_c('SubmitBtn',{attrs:{"passCheckAllowSubmit":_vm._checkAllowSubmit},on:{"handleSubmitBtn":_vm._submitBetting,"handleClearBtn":_vm._clearMoney}}),_c('LimitBetting',{attrs:{"passWeight":_vm.passWeight}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }