
export default {
    methods: {
        _displayCurrencySymbol(user_account_currency) {
            switch (user_account_currency) {
                case 1:
                    return '$'
                case 2:
                    return '៛'
                case 3:
                    return '฿'
                case 4:
                    return '₫'
                case 5:
                    return '฿'
                default:
                    return '$'
            }
        },
        _formatAmountCurrency(userInfo, amount) {
            switch (userInfo.account_currency) {
                case 1:
                    return parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                case 2:
                    return parseInt(amount).toLocaleString('en');
                case 3:
                    return parseInt(amount).toLocaleString('en');
                case 4:
                    return parseInt(amount).toLocaleString('en');
                case 5:
                    return parseInt(amount).toLocaleString('en');
                default:
                    return parseFloat(amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            }
        },
        _formatCurrency(userInfo) {
            switch (userInfo.account_currency) {
                case 1:
                    return parseFloat(userInfo.balance).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                case 2:
                    return parseInt(userInfo.balance).toLocaleString('en');
                case 3:
                    return parseInt(userInfo.balance).toLocaleString('en');
                case 4:
                    return parseInt(userInfo.balance).toLocaleString('en');
                case 5:
                    return parseInt(userInfo.balance).toLocaleString('en');
                default:
                    return parseInt(userInfo.balance).toLocaleString('en');
            }
        },

        correctChannelName(channelType) {
            switch (true) {
                case (channelType == 1):
                    return require('../assets/images/channel-icons/cockfight icon 1.png')
                case (channelType == 2):
                    return require('../assets/images/channel-icons/cockfight icon 2.png');
                case (channelType == 3):
                    return require('../assets/images/channel-icons/cockfight icon 3.png');
                case (channelType == 4):
                    return require('../assets/images/channel-icons/cockfight icon 4.png');
                case (channelType == 5):
                    return require('../assets/images/channel-icons/kla-klok icon 1.png');
                case (channelType == 6):
                    return require('../assets/images/channel-icons/baccarat icon 1.png');
                case (channelType == 7):
                    return require('../assets/images/channel-icons/baccarat icon 2.png');
                case (channelType == 8):
                    return require('../assets/images/channel-icons/dragon tiger icon 1.png');
                case (channelType == 9):
                    return require('../assets/images/channel-icons/dragon tiger icon 2.png');
                case (channelType == 10):
                    return require('../assets/images/channel-icons/lotto icon 1.png');
                case (channelType == 11):
                    return require('../assets/images/channel-icons/yuki icon 1.png');
                case (channelType == 12):
                    return require('../assets/images/channel-icons/aporng icon 1.png');
                case channelType == 13:
                    return require('../assets/images/channel-icons/fantan icon 1.png');
                case channelType == 14:
                    return require('../assets/images/channel-icons/taisiev icon 1.png');
                case (channelType == 15):
                    return require('../assets/images/channel-icons/baccarat icon 4.png');
                case (channelType == 16):
                    return require('../assets/images/channel-icons/Roulette icon.png');
                case (channelType == 17):
                    return require('../assets/images/channel-icons/aporng icon 2.png');
                case (channelType == '18'):
                    return require('../assets/images/channel-icons/baccarat icon 3.png');
                case (channelType == '19'):
                    return require('../assets/images/channel-icons/dragon tiger icon 3.png');
                case (channelType == '20'):
                    return require('../assets/images/channel-icons/Han_2.png');
                case (channelType == '21'):
                    return require('../assets/images/channel-icons/Han_3.png');
                default:
                    return '';
            }
        },
        getGameName(channelType) {
            switch (true) {
                case (channelType == '1' || channelType == 2 || channelType == 3 || channelType == 4):
                    return 'COCK FIGHT(' + channelType + ')'
                case (channelType == 15):
                    return 'BACCARAT(4)'
                case (channelType == 16):
                    return 'ROULETTE'
                case channelType == 5:
                    return 'KLA KLOUK'
                case channelType == 6:
                    return 'BACCARAT(1)'
                case channelType == 7:
                    return 'BACCARAT(2)'
                case (channelType == 8):
                    return 'DRAGON TIGER(1)'
                case (channelType == 9):
                    return 'DRAGON TIGER(2)'
                case channelType == 10:
                    return 'LOTTO'
                case channelType == 11:
                    return 'YUKI'
                case (channelType == 12 || channelType == 17):
                    return 'APONG'
                case channelType == 13:
                    return 'FAN TAN'
                case channelType == 14:
                    return 'TAI SIEV';
                case (channelType == 19):
                    return 'DRAGON TIGER(3)'
                case (channelType == 18):
                    return 'BACCARAT(3)'
                case (channelType == 20):
                    return 'Han 2'
                case (channelType == 21):
                    return 'Han 3'
            }
        },
        formatMoney(amount) {
            return parseFloat(amount).toLocaleString("en");
        },
        _selectedGame(selectedChannel) {
            switch (true) {
                case (selectedChannel == 1 || selectedChannel == 2
                    || selectedChannel == 3 || selectedChannel == 4
                    || selectedChannel == 15 || selectedChannel == 16):
                    return 'cock'
                case selectedChannel == 5:
                    return 'klaklouk'
                case selectedChannel == 6 || selectedChannel == 7 || selectedChannel == 19:
                    return 'baccarat'
                case selectedChannel == 8 || selectedChannel == 9:
                    return 'tiger_dragon'
                case selectedChannel == 10:
                    return 'lotto'
                case selectedChannel == 11:
                    return 'yuki'
                case (selectedChannel == 12 || selectedChannel == 17):
                    return 'apong'
                case selectedChannel == 13:
                    return 'fantan'
                case selectedChannel == 14:
                    return 'taisiev'
                case selectedChannel == 20:
                    return 'han2'
                case selectedChannel == 21:
                    return 'han3'
                default:
                    return ''
            }
        },
        sameGameFindName(channelType) {
            switch (true) {
                case (channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4):
                    return 'title.c_'
                case (channelType == 15):
                    return 'title.bac4_'
                case (channelType == 16):
                    return 'title.roulette_'
                default:
                    return 'title.'
            }
        },
        _sameGameCheckTieColorStyle(channelType) {
            switch (true) {
                case (channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4):
                    return 'tie-section'
                case (channelType == 15):
                    return 'tie-section'
                case (channelType == 16):
                    return 'black-section'
            }
        },
        _sameGameCheckBlueColorStyle(channelType) {
            switch (true) {
                case (channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 ):
                    return 'wala-section';
                case (channelType == 15):
                    return 'wala-section'
                case (channelType == 16):
                    return 'black-section'
            }
        },
        _sameGameCheckPayout(channelType, displayFor, payout) {
            switch (true) {
                case (channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4):
                    return payout
                case (channelType == 15):
                    return payout
                case (channelType == 16 && displayFor == 'meron'):
                    return payout
                case (channelType == 16 && displayFor == 'wala'):
                    return payout
                case (channelType == 16 && displayFor == 'tie'):
                    return '26'
            }
        },
        detectTrendColor(channelType, displayFor) {
            switch (true) {
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 16 || channelType == 18 || channelType == 19) && displayFor == 'meron'):
                    return 'trend-meron'
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15       || channelType == 18 || channelType == 19) && displayFor == 'wala'):
                    return 'trend-wala'
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 18 || channelType == 19) && displayFor == 'tie'):
                    return 'trend-draw'
                case (channelType == 16 && displayFor == 'wala'):
                    return 'trend-black'
                case (channelType == 16 && displayFor == 'tie'):
                    return 'trend-draw'
            }
        },
        _resultColor(channelType, color) {
            switch (true) {
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 16 || channelType == 18 || channelType == 19) && color == 'cir-red'):
                    return 'trend-meron'
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 18 || channelType == 19) && color == 'cir-blue'):
                    return 'trend-wala'
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 18 || channelType == 19) && color == 'cir-green'):
                    return 'trend-draw'
                case (channelType == 16 && color == 'cir-blue'):
                    return 'trend-black'
                case (channelType == 16 && color == 'cir-green'):
                    return 'trend-draw'
                case color == 'cir-cancel':
                    return 'trend-cancel'
            }
        },
        _bettingColor(channelType, color) {
            switch (true) {
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 16 || channelType == 18 || channelType == 19) && color == 'cir-red'):
                    return 'cir-red'
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 18 || channelType == 19) && color == 'cir-blue'):
                    return 'cir-blue'
                case ((channelType == 1 || channelType == 2 || channelType == 3 || channelType == 4 || channelType == 15 || channelType == 18 || channelType == 19) && color == 'cir-green'):
                    return 'cir-green'
                case (channelType == 16 && color == 'cir-blue'):
                    return 'cir-black'
                case (channelType == 16 && color == 'cir-green'):
                    return 'cir-green'
                case color == 'cir-cancel':
                    return 'trend-cancel'
            }
        },
        _klakloukNameToimg(name){
            switch (true) {
                case (name == 'Tiger' || name == 'ខ្លា'):
                    return require('../../public/images/klaklok/0007.png')
                case (name == 'Cock' || name == 'មាន់'):
                    return require('../../public/images/klaklok/0003.png')
                case (name == 'Groud' || name == 'ឃ្លោក'):
                    return require('../../public/images/klaklok/0002.png')
                case (name == 'Lobster' || name == 'បង្កង'):
                    return require('../../public/images/klaklok/0004.png')
                case (name == 'Crab' || name == 'ក្ដាម'):
                    return require('../../public/images/klaklok/0005.png')
                case (name == 'Fish' || name == 'ត្រី'):
                    return require('../../public/images/klaklok/0006.png')
                case (name == 'CANCEL' || name == 'Cancel'):
                        return require('../../public/images/klaklok/0010.png')
                default: 
                    return name
            }
        }
    }
};