/* eslint-disable no-empty-pattern */
import Request from '@/services/axios'
let request = new Request;

const fetchChannel = async ({commit}, data) => {
    const response = await request.get({
        url: 'channel',
        data
    })
    if (response.data.code) {
        if( response.data.data.results) commit('UPDATE_LOTTO', response.data.data.results);
        if( response.data.data.results) commit('UPDATE_KLAKLOUK', response.data.data.results);
        if( response.data.data.results) commit('UPDATE_TAISIV', response.data.data.results);
        if( response.data.data.results) commit('UPDATE_FANTAN', response.data.data.results);
        commit('UPDATE_AMOUNT_MIN', response.data.data.amount_min);
        commit('UPDATE_AMOUNT_MX', response.data.data.amount_max);
        commit('LATEST_CHANNEL', response.data.data);
        commit('UPDATE_CHANNEL_STATUS', response.data.data.channel_status);
    }
};
const fetchNumberMaster = async ({commit}, data) => {
    const response = await request.get({
        url: 'number',
        data
    })
    if (response.data.code) {
        commit('LATEST_NUMBER_MASTER', response.data.data);
    }
};
const fetchBettingTypeMaster = async ({commit}, data) => {
    const response = await request.get({
        url: 'type',
        data
    })
    if (response.data.code) {
        commit('LATEST_BETTING_TYPE_MASTER', response.data.data);
    }
};
const fetchResult = async ({commit}, data) => {
    const response = await request.get({
        url: 'result',
        data
    })
    if (response.data.code) {
        commit('LATEST_RESULT', response.data.data);
    }
};
const fetchTodayBetting = async ({commit}, data) => {

    const response = await request.get({
        url: 'betting/today',
        data
    })
    if (response.data.code) {
        commit('TODAY_BETTING', response.data.data);
 
    }
};
const fetchCurrentBetting = async ({commit}, data) => {
    commit('LOADING_TODAY_REPORT', true);
    const response = await request.get({
        url: 'betting/current',
        data
    })
    if (response.data.code) {
        commit('CURRENT_BETTING', response.data.data);
    }
    commit('LOADING_TODAY_REPORT', false);
};
const fetchButton = async ({commit}, data) => {
    const response = await request.get({
        url: 'button',
        data
    })
    if (response.data.code) {
        commit('LATEST_BUTTON', response.data.data);
    }
};
const fetchCreateBetting = async ({commit}, data) => {
    commit('BETTING_DIALOG', true);
    return await request.post({
        url: 'betting',
        data
    })
};
const updateCurrentBetting = async({ commit }, bettingItem) => {
    commit('UPDATE_CURRENT_BETTING', bettingItem);
};
const updateChannel = async({ commit }, channel) => {
    commit('UPDATE_CHANNEL', channel);
};
const updateResult = async({ commit }, result) => {
    commit('UPDATE_RESULT', result);
};

const fetchVideoToken = async ({}, data) => {
    return await request.get({
        url: 'user/video',
        data
    })
};
const fetchVideoOneToken = async ({}, data) => {
    return await request.switchVideo({
        url: 'user/video',
        data
    })
};
const fetchWeight = async ({commit}, data) => {
    const response = await request.get({
        url: 'weight',
        data
    })
    if (response.data.code) {
        commit('LATEST_WEIGHT', response.data.data);
    }
};
const _inputAmount =  ({}, data) => {
    console.log(data)
};
export default {
	fetchChannel,
    fetchNumberMaster,
    fetchBettingTypeMaster,
    fetchResult,
    fetchTodayBetting,
    fetchCurrentBetting,
    fetchCreateBetting,
    updateCurrentBetting,
    updateChannel,
    updateResult,
    fetchWeight,
    fetchButton,
    fetchVideoToken,
    fetchVideoOneToken,
    _inputAmount
};
