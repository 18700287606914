<template>
  <!-- eslint-disable -->
  <div>
    <v-btn 
      @click="print(todayReport._id)"
      x-small color="purple"
      id="print-button"
      style="color:white!important"
    >
    {{$t('title.print')}}
    </v-btn >
    <table :id="`printMe${todayReport._id}`" hidden>
      <tbody>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            WA855.vip
          </td>
        </tr>
        <br />
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            Bet ID: {{ todayReport._id }}
          </td>
        </tr>
        <br />
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('header.fight_no') }}:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ todayReport.fight_no ? todayReport.fight_no : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('header.bettingType') }}:</td>
                  <td style="padding: 5px; text-align: left">
                    {{ todayReport.betting ?  $t(sameGameFindName(passSelectedChannel) + todayReport.betting): "" }} 
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('header.date') }}:</td>
                  <td style="padding: 5px; text-align: left">
                    {{todayReport.time}}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('title.cast') }}</td>
                  <td style="padding: 5px; text-align: left">
                    <span>{{todayReport.result != null ? todayReport.result : todayReport.amount != null ? todayReport.amount : todayReport}}</span>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('header.result') }}</td>
                  <td style="padding: 5px; text-align: left" 
                    v-if="todayReport.result_name">
                    {{ todayReport.result_name ?  $t(sameGameFindName(passSelectedChannel) + todayReport.result_name): "" }} 
                  </td>
               
                  <td style="padding: 5px; text-align: left" v-else-if="!todayReport.result_name">
                         {{ todayReport && todayReport.result1_name ? _getResultClass(todayReport.result1_name).n+", " :"" }}
                                            {{ todayReport &&  todayReport.result1_name ? _getResultClass(todayReport.result2_name).n+", " :"" }}
                                            {{ todayReport &&  todayReport.result1_name ? _getResultClass(todayReport.result3_name).n :"" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{$t('header.winLose')}}</td>
                  <td style="padding: 5px; text-align: left">
                    <span>{{todayReport.is_win ? $t('title.win') :$t('title.lose') }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
import {  mapGetters} from "vuex";
export default {
  props: ["todayReport"],
  computed: {
        ...mapGetters("$_modules", {
          passSelectedChannel: "getChannelId"
        }),

    },
  methods: {
    async print(id) {
      // Pass the element id here
      await this.$htmlToPaper(`printMe${id}`);
    },

    _getResultClass(x) {
            switch (x) {
                case 'Banker 1':
                    return { c: 'han2-r-td-banker', n: 'B1', f: 'B', cf: 'han2-r-td-banker-f' }
                case 'Banker 2':
                    return { c: 'han2-r-td-banker', n: 'B2', f: 'B', cf: 'han2-r-td-banker-f' }
                case 'Banker 3':
                    return { c: 'han2-r-td-banker', n: 'B3', f: 'B', cf: 'han2-r-td-banker-f' }
                case 'Player 1':
                    return { c: 'han2-r-td-player', n: 'P1', f: 'P', cf: 'han2-r-td-player-f' }
                case 'Player 2':
                    return { c: 'han2-r-td-player', n: 'P2', f: 'P', cf: 'han2-r-td-player-f' }
                case 'Player 3':
                    return { c: 'han2-r-td-player', n: 'P3', f: 'P', cf: 'han2-r-td-player-f' }
                case 'CANCEL':
                    return { c: 'han2-r-td-cancel', n: 'C', f: 'C', cf: 'han2-r-td-cancel-f' }
                case 'Tie':
                    return { c: 'han2-r-td-tie', n: 'T', f: 'T', cf: 'han2-r-td-tie-f' }
                case 'Tie 1':
                    return { c: 'han2-r-td-tie', n: 'T1', f: 'T', cf: 'han2-r-td-tie-f' }
                case 'Tie 2':
                    return { c: 'han2-r-td-tie', n: 'T2', f: 'T', cf: 'han2-r-td-tie-f' }
                case 'Tie 3':
                    return { c: 'han2-r-td-tie', n: 'T3', f: 'T', cf: 'han2-r-td-tie-f' }
                default:
                    return ''
            }
        },
  },
};
</script>