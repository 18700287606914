<template>
  <div>
    <v-tabs fixed-tabs class="tab-container">
      <v-tabs-slider></v-tabs-slider>
      <v-tab class="txt-white pt-4">
        <span class="cl-icon">
          <v-icon left class="txt-white"> mdi-account </v-icon>
        </span>
        <span class="txt-white "> {{ $t("title.persional_info") }} </span>
      </v-tab>
      <v-tab class="pt-4">
        <span class="cl-icon">
          <v-icon left class="txt-white"> mdi-lock </v-icon>
        </span>
        <span class="txt-white"> {{ $t("title.change_password") }} </span>
      </v-tab>
      <v-tab-item>
        <template>
          <v-form class="position-relative">
            <v-container style="background: none !important">
              <v-row class="form-position">
                <v-col cols="12" md="12">
                  <v-text-field
                    style=""
                    v-model="userInfor.user_name"
                    readonly
                    label="Name"
                    outlined
                    required
                    disabled
                    class="input-field-style"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
               
                    v-model="userInfor.account_name"
                    label="Account Name"
                    outlined
                    required
                    class="input-field-style"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="userInfor.account_number"
                    label="Account Number"
                    outlined
                    required
                    class="input-field-style"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                 
                    v-model="userInfor.account_info"
                    label="Bank Name"
                    outlined
                    required
                    class="input-field-style"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  style="width: 100%; text-align: center"
                >
                  <v-btn
                    @click="updateMyInfo()"
                    :loading="loading"
                    class="btn-submit-style"
                  >
                    <span class="txt-white">
                      {{ $t("button.update") }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
      </v-tab-item>
      <v-tab-item>
        <template>
          <v-form class="position-relative">
            <v-container style="background: none !important">
              <v-row class="form-position">
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="currentpassword"
                    outlined
                    required
                    :rules="[
                      () => !!currentpassword || 'This field is required',
                    ]"
                    label="Current Password"
                    hint="At least 8 characters"
                    class="input-field-style input-group--focused"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="newpassword"
                    outlined
                    required
                    name="input-10-2"
                    :rules="[() => !!newpassword || 'This field is required']"
                    label="New Password"
                    hint="At least 8 characters"
                    class="input-field-style input-group--focused"
                    counter
                  >
                  </v-text-field>

                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="confirmedpassword"
                      name="input-10-2"
                      :rules="[
                        () => !!confirmedpassword || 'This field is required',
                      ]"
                      label="Confirm Password"
                      hint="At least 8 characters"
                      outlined
                      required
                      class="input-field-style input-group--focused"
                      counter
                    >
                    </v-text-field>
                  </v-col>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  style="width: 100%; text-align: center"
                >
                  <v-btn
                    @click="changePassword()"
                    :loading="loading"
                    class="btn-submit-style"
                  >
                    <span class="txt-white">
                      {{ $t("button.change_password") }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
      </v-tab-item>
    </v-tabs>
  </div>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {},
  data() {
    return {
      currentpassword: null,
      newpassword: null,
      confirmedpassword: null,
      accname: null,
      accnumber: null,
      accinfo: null,
      accbalance: null,
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      loading: false,
      dialog: false,
      updateMyInfoObj: {
        account_info: null,
        account_name: null,
        account_number: null,
      },
      changePasswordObj: {
        old_password: null,
        new_password: null,
      },
      account_info: null,
      dataRB: {
        page: 1,
        rowsPerPage: 10,
      },
      dataRW: {
        page: 1,
        rowsPerPage: 10,
      },
      dateFilterRB: {},
      dateFilterRW: {},
    };
  },
  item: {
    type: Object,
    default: null,
  },
  async created() {
    await this.myInfo();
  },
  watch: {
    dataIsUpdateUser: function () {},
    dataIsChangePwd: function () {},
  },
  computed: {
    ...mapGetters("$_myinfo", {
      dataReportBetting: "getReportBetting",
      dataReportWin: "getReportWin",
      dataReportStatement: "getReportStatement",
      dataIsUpdateUser: "getIsUpdateUser",
      dataIsChangePwd: "getIsChangePwd",
    }),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapGetters("$_myinfo", ["getLoading"]),
  },
  methods: {
    async myInfo() {
      try {
        this.loading = true;
        const response = await this.fetchMyInfo();
        if (response.data.code) {
          (this.accname = response.data.data.account_name),
            (this.accnumber = response.data.data.account_number),
            (this.accinfo = response.data.data.account_info),
            (this.accbalance = response.data.data.balance);
          await this.UPDATE_USER_INFO(response.data.data);
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        return error;
      } finally {
        this.loading = false;
      }
    },

    async updateMyInfo() {
      try {
        this.loading = true;
        this.updateMyInfoObj = {
          account_info: this.userInfor.account_info,
          account_name: this.userInfor.account_name,
          account_number: this.userInfor.account_number,
        };
        await this.fetchUpdateMyInfo(this.updateMyInfoObj);
        if (this.dataIsUpdateUser === 1) {
          this.$toastr.s("Your information update sucess!");
          this.loading = false;
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.$toastr.e("Your information update fail!");
        this.loading = false;
        return error;
      }
    },
    onLogout() {
      this.$socket.removeAllListeners()
      this.$socket.disconnect()
      this.$cookies.remove("wa855_channel_type");
      this.$cookies.remove("wa855_token");
      this.$router.push("/login");
    },
    async changePassword() {
      if (
        this.currentpassword == null ||
        this.newpassword == null ||
        this.confirmedpassword == null
      ) {
        this.$toastr.e(
          "Please input current password or new password or confirmed password!"
        );
        this.loading = false;
      } else if (this.newpassword == this.confirmedpassword) {
        try {
          this.loading = true;
          this.changePasswordObj = {
            old_password: this.currentpassword,
            new_password: this.newpassword,
          };
          await this.fetchChangePassword(this.changePasswordObj);
          if (this.dataIsChangePwd.code === 1) {
            this.$toastr.s(this.dataIsChangePwd.message.descriptions);
            this.loading = false;
            // this.onLogout();
          } else {
            this.error = true;
          }
        } catch (error) {
          this.error = true;
          this.$toastr.e(error.message);
          this.loading = false;
          return error;
        }
      } else {
        this.$toastr.e("The password confirmed not match!");
        this.loading = false;
      }
    },
    ...mapMutations("$_modules", ["UPDATE_USER_INFO"]),
    ...mapMutations("$_myinfo", [
      "UPDATE_MY_INFO",
      "CHANGE_PASSWORD",
      "IS_UPDATE_USER",
      "IS_CHANGE_PASSWORD",
    ]),
    ...mapActions("$_myinfo", [
      "fetchMyInfo",
      "fetchUpdateMyInfo",
      "fetchChangePassword",
    ]),
  },
};
</script>
<style>
.input-field-style input:focus{
  padding:0px!important;
}
.input-field-style input{
  padding:0px!important;
}
.v-tabs-bar {
  background: rgba(0, 0, 0, 0.2) !important;
  color: white !important;
}

.theme--light.v-tabs-items {
  background: rgba(0, 0, 0, 0.0) !important;
}

@media (max-width: 649px) {
  .tab-container {
    max-width: 100%;
    margin: auto;
    padding-top: 0px;
  }

  .input-field-style {
    max-width: 100%;
    width: 70vw;
    margin: auto !important;
  }

  .btn-submit-style {
    width: 70vw!important;
    background-color: rgba(255, 128, 171, 0.5) !important;
    border-color: 2px solid rgba(77, 16, 36, 0.7) !important;
  }
  .v-slide-group__wrapper {
    /* margin-left:-35px!important; */
  }
  .cl-icon {
    display: none;
  }
}

@media (min-width: 650px) {
  .tab-container {
    max-width: 100%;
    margin: auto;
    margin-top: -6px;
  }

  .input-field-style {
    max-width: 100%;
    width: 400px;
    margin: auto !important;
  }

  .btn-submit-style {
    width: 400px!important;
    background-color: rgba(255, 128, 171, 0.5) !important;
    border-color: 2px solid rgba(77, 16, 36, 0.7) !important;
  }
}

.txt-white {
  color: white !important;
}

.card-backround {
  background-color: #013366;
  border-radius: 0px;
}

.form-position {
  margin-top: 70px !important;
}
.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: rgba(256, 256, 256, 0.33) !important;
}
.v-label {
  color: rgba(256, 256, 256, 0.33) !important;
}
.theme--light.v-counter {
  color: rgba(256, 256, 256, 0.33) !important;
}

.custom-placeholer-color input::placeholder {
  color: white !important;
  opacity: 1;
}

.custom-label-color .v-label {
  color: white;
  opacity: 1;
}

.custom-placeholer-color input,
.custom-label-color input {
  color: white !important;
}

.v-label {
  color: white !important;
}

.theme--light.v-input,
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: rgba(256, 256, 256, 0.87) !important;
}

.v-messages__message {
    color: white !important;
}
</style>