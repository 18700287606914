<template>
  <v-flex class="fluid" xs12 md12 style="background-color:#003366;color:#ffffff">

    <div id="video-container" class="video-container" :class="channel.is_stop_video ? 'black-screen' : ''">
      <div v-if="channel.is_stop_video" style="background-color:#000000"></div>
      <div v-else>
        <iframe :src="urlToken" frameborder="0" class="iframe-vid" width="100%" height="100%" allowfullscreen>
        </iframe>
        <div style="position:relative; left:0; color:#ffffff; font-weight:bold; padding:10px; padding-bottom:0; width:100%; text-align:center">
          <v-btn v-if="isOne" class="mx-2" icon dark large color="indigo" v-on:click='getVideoOneToken()'>
            <v-icon dark color="indigo">
              mdi-eye
            </v-icon>
            2
          </v-btn>
          <v-btn v-else class="mx-2" icon dark large color="indigo" v-on:click='getVideoToken()'>
            <v-icon dark color="indigo">
              mdi-eye
            </v-icon>
            1
          </v-btn>
        </div>
        <div v-if="channelObj.is_show_username" style="position:absolute; left:0; width:100%; z-index:4; opacity:5%">
          <div style="position:relative; left:0; color:#000000; font-weight:bold; padding:10px; padding-bottom:0; width:100%; text-align:center">
            <p style="padding:20px 0px 0px 0px; font-size:20px">{{ this.userInfor.user_name }}</p>
          </div>
        </div>
      </div>
    </div>
  </v-flex>
</template>

<script>

// import ResultVideoDragon from "./_ResultOnVideo/ResultVideoTigerDragon.vue";
// import ResultVideoBaccarat from "./_ResultOnVideo/ResultVideoBaccarat.vue";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
// import ResultVideoKlaKlok from "./_ResultOnVideo/ResultVideoKlaKlok.vue";
// import ResultVideoLotto from './_ResultOnVideo/ResultVideoLotto.vue';
// import ResultVideoYuki from './_ResultOnVideo/ResultVideoYuki.vue';
// import ResultVideoApong from './_ResultOnVideo/ResultVideoApong.vue';
export default {
  // components: { ResultVideoDragon, ResultVideoBaccarat, ResultVideoKlaKlok, ResultVideoLotto, ResultVideoYuki, ResultVideoApong },
  props: ["channel", "chanels", "withdrawDialog", 'passSelectedChannel'],
  data() {
    return {
      sample_text:
        "(SET-A) GAPP ISABELA/CAGAYAN MBC 3-STAG ELIMS, (SET-B) JCAP PALITO 7-STAG SUPER BIG EVENT, 343 FIGHTS",
      currenttime: "",
      urlToken: "",
      isOne: true
    };
  },
  computed: {
    ...mapGetters("$_dashboard_live", {
      channelObj: "getChannel",
      // Tiger Dragon
      dragonCard: "getDragonCard",
      tigerCard: "getTigerCard",
      //Baccarat
      playerCard1: "getPlayerCard1",
      playerCard2: "getPlayerCard2",
      playerCard3: "getPlayerCard3",
      bankerCard1: "getBankerCard1",
      bankerCard2: "getBankerCard2",
      bankerCard3: "getBankerCard3",
      bankerPoint: "getBankerPoint",
      playerPoint: "getPlayerPoint",
      //Lotto
      listLotto: "getListLotto",
      // klaklouk
      listKlaklouk: "getListKlaklouk",
      // abong
      listAbong: "getListAbong",
      // yuki
      listYuki: "getListYuki"
    }),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapState("$_dashboard_live", {
      vdolinks: "vdolinks",
    })
  },
  watch: {
    vdolinks: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.urlToken = ""
        this.getVideoToken()
      }
    },
  },
  methods: {
    async getVideoToken() {
      this.isOne = true
      this.urlToken = ""
      try {
        const response = await this.fetchVideoToken();
        if (response.data.code) {
          // if (response.data.data.token && response.data.data.playOrder) {
          //   this.urlToken = `${this.getUrl()}&playOrder=${response.data.data.playOrder}&token=${response.data.data.token}`;
          // } else if (response.data.data.token && !response.data.data.playOrder) {
            this.urlToken = `${this.getUrl(0)}&token=${response.data.data.token}`;
          // } else if (!response.data.data.token && response.data.data.playOrder) {
          //   this.urlToken = `${this.getUrl()}&playOrder=${response.data.data.playOrder}`;
          // } else {
          //   this.urlToken = this.getUrl();
          // }
        } else {
          this.$toastr.e(`${response.data.message.descriptions}`)
        }
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },
    async getVideoOneToken() {
      this.isOne = false
      this.urlToken = ""
      try {
        const response = await this.fetchVideoOneToken();
        if (response.data.code) {
          this.urlToken = `${this.getUrl(1)}&token=${response.data.data.token}`;
        } else {
          this.$toastr.e(`${response.data.message.descriptions}`)
        }
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },
    getUrl(i) {
      if (
        this.vdolinks &&
        (this.vdolinks[0] || this.vdolinks[1])
      ) {
        // if (this.vdolinks[0]) {
          return this.urlToken = this.vdolinks[i];
        // } else {
        //   return this.urlToken = this.vdolinks[1];
        // }
      }
    },
    handler: function (e) {
      //do stuff
      e.preventDefault();
    },

    

    ...mapActions("$_dashboard_live", [
      "fetchVideoToken",
      "fetchVideoOneToken"
    ]),
    ...mapMutations("$_dashboard_live", [
      "UPDATE_TIGER_CARD",
      "UPDATE_DRAGON_CARD"
    ]),
  },
};
</script>


<style>
@import "https://vjs.zencdn.net/7.5.4/video-js.css";
</style>

<style scoped>
.black-screen {
  background-color: black;
}

.v-container {
  border: 2px solid black;
  border-top: 0px
}

.video-container {
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  /* Change this till it fits the dimensions of your video */
  position: relative;
  margin-top: -2px;
}

.iframe-vid {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

@media (min-width: 1920px) {
  .maintenance {
    font-size: 40px;
  }

  .button-switch {
    margin-left: 200px !important;
  }

  .switch-btn {
    background-color: #1f2024 !important;
    color: white;
    width: 110px;
    margin-right: 240px
  }
}

@media (min-width: 1200px) and (max-width: 1919px) {
  .maintenance {
    font-size: 40px;
    margin-top: 80px !important;
  }

  .button-switch {
    margin-left: 200px !important;
  }

  .switch-btn {
    background-color: #1f2024 !important;
    color: white;
    width: 110px;
    margin-right: 240px
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .maintenance {
    font-size: 34px;
    margin-top: 80px !important;
  }

  .button-switch {
    margin-left: 200px !important;
  }

  .switch-btn {
    background-color: #1f2024 !important;
    color: white;
    width: 140px;
    margin-right: 240px
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .maintenance {
    font-size: 30px !important;
    margin-top: 60px !important;
  }

  .button-switch {
    margin-left: 200px !important;
  }

  .switch-btn {
    background-color: #1f2024 !important;
    color: white;
    width: 110px;
    margin-right: 240px
  }

  .change-link {
    display: none;
  }
}

@media (min-width: 515px) and (max-width: 767px) {
  .button-switch {
    margin-left: 200px !important;
  }

  .switch-btn {
    background-color: #1f2024 !important;
    color: white;
    width: 110px;
    margin-right: 240px
  }

  .maintenance {
    font-size: 24px !important;
    margin-top: 60px !important;
  }

  .change-link {
    display: none;
  }
}

@media (max-width: 515px) {
  .button-switch {
    margin-left: 200px !important;
  }

  .switch-btn {
    background-color: #1f2024 !important;
    color: white;
    width: 110px;
    margin-right: 240px
  }

  .maintenance {
    font-size: 20px !important;
    margin-top: 40px !important;
  }

  .change-link {
    display: none;
  }
}

.maintenance {
  position: absolute;
  top: 0;
  background: rgba(36, 32, 32, 0.514);
  /* Fallback for older browsers without RGBA-support */
  width: 100%;
  height: auto;
  -webkit-text-stroke: 2px white;
  -webkit-text-fill-color: blue;
  text-align: center;
  font-weight: bold !important;
  margin: auto;

}

.button-switch {
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  text-align: right;
  margin: auto;
}

.current-time-betting {
  font-size: 18px;
}

.yt-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 41.3%;
  left: 0;

}

.yt-cover-mb {
  position: absolute;
  top: 3%;
  bottom: 20%;
  right: 0;
  left: 0;

}

.current-time-betting {
  font-size: 18px;
}

.yt-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 41.3%;
  left: 0;

}

.yt-cover-mb {
  position: absolute;
  top: 3%;
  bottom: 20%;
  right: 0;
  left: 0;

}

.video-container {
  position: relative;
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
}

.video-container:hover {
  cursor: pointer;
}

.elem-1 {
  /* background: rgb(243, 65, 65); */
  width: 50%;
  height: 40px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  z-index: 1000;
  background: linear-gradient(to right, rgba(22, 125, 241), rgba(0, 0, 0, 0.7));
  background-position: 56%, 0, 100% 0;
  border-style: solid;
  border-width: 2px;
  -o-border-image: linear-gradient(45deg, rgba(22, 125, 241), white, rgba(22, 125, 241)) 1;
  border-image: linear-gradient(45deg, rgba(22, 125, 241), white, rgba(22, 125, 241)) 1;

  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;

}

.elem-2 {

  width: 50%;
  height: 40px;
  content: "";
  position: absolute;
  right: 0;
  bottom: 0px;
  z-index: 1000;
  background-position: 56%, 0, 100% 0;
  border-style: solid;
  border-width: 2px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(243, 65, 65, 1));
  -o-border-image: linear-gradient(45deg, rgba(243, 65, 65), white, rgba(243, 65, 65)) 1;
  border-image: linear-gradient(45deg, rgba(243, 65, 65), white, rgba(243, 65, 65)) 1;
  border-bottom: 0px;
  border-left: 0px;
  border-right: 0px;
  /* background: rgb(0, 0, 0,0.5); */

}

/* Show the controls (hidden at the start by default) */

/* Make the CDN fonts accessible from the CSS */
@font-face {
  font-family: 'VideoJS';
  src: url('https://vjs.zencdn.net/f/1/vjs.eot');
  src: url('https://vjs.zencdn.net/f/1/vjs.eot?#iefix') format('embedded-opentype'),
    url('https://vjs.zencdn.net/f/1/vjs.woff') format('woff'),
    url('https://vjs.zencdn.net/f/1/vjs.ttf') format('truetype');
}

#video-container .button-switch {
  display: none !important;
}

#video-container:hover .button-switch {
  display: block !important;
}

video[poster] {
  object-fit: fill;
}

.card-style {
  width: 25px;
  height: 30px;
  margin-top: 3.5px;
}
</style>
