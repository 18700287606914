<template>
  <div class="px-2 max-label row ma-0" style="justify-content: space-between">
    <v-spacer></v-spacer>
    <p v-if="_selectedGame(channelId) == 'cock'">
      {{ $t("title.min") }}
      <span class="focus-text"
        >{{ formatMoney(weightObj.amount_min) }}
        {{ _displayCurrencySymbol(userInfo.account_currency) }}</span
      >
      | {{ $t("title.max") }}
      <span class="focus-text"
        >{{ formatMoney(weightObj.amount_max)
        }}{{ _displayCurrencySymbol(userInfo.account_currency) }}</span
      >
    </p>
    <p v-else>
      {{ $t("title.min") }}
      <span class="focus-text"
        >{{ formatMoney(amountMin)
        }}{{ _displayCurrencySymbol(userInfo.account_currency) }}
      </span>
      | {{ $t("title.max") }}
      <span class="focus-text"
        >{{ formatMoney(amountMax)
        }}{{ _displayCurrencySymbol(userInfo.account_currency) }}</span
      >
    </p>
  </div>
</template>

<script>
import "@/assets/css/homepage.css";
import { mapGetters, mapActions } from "vuex";
import Mixin from "../mixin/index";
export default {
  props: ["passWeight"],
  data() {
    return {
      mixins: [Mixin],
    };
  },
  computed: {
    ...mapGetters("$_dashboard", {
      amountMin: "getAmountMin",
      amountMax: "getAmountMax",
      weightObj: "getWeight",
    }),
    ...mapGetters("$_modules", {
      channelId: "getChannelId",
      userInfo: "getUserInfo",
    }),
  },
  async created() {
    this._selectedGame(this.channelId) == "cock" ? await this.fetchWeight : "";
  },
  methods: {
    ...mapActions("$_dashboard", [
      "fetchWeight",
    ]),
  },
};
</script>
<style scoped>
.max-label {
  width: 100%;
  height: 30px;
  margin-bottom: -9px;
  font-family: "Koh Santepheap", cursive, serif, cursive, Nunito, Segoe UI,
    sans-serif;
}
.format-currency {
  font-size: 1.2rem;
  margin-top: -2px;
  font-weight: 500;
  color: gold;
  margin-left: 1px;
}
.focus-text {
  color: orangered;
  font-weight: 600;
}
</style>