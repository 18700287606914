export default {
	treaments: 'ការព្យាបាល',
	patient: 'អ្នកជំងឺ',
	doctor: 'វេជ្ជបណ្ឌិត',
	date: 'cxxxx',
	discount: 'បញ្ចុះតំលៃ',
	total_price: 'តម្លៃ​សរុប',
	price: 'តម្លៃ​',
	discount_for_all: 'បញ្ចុះតំលៃទាំងអស់',
	quantity: 'បរិមាណ',
	unit_price: 'តម្លៃ​ឯកតា',
	treatment_name: 'ឈ្មោះព្យាបាល',
	services: 'សេវាកម្ម',
	cashier: "បេឡាករ",


	ticket_id : "Vé ID",
	game_id: "Trò chơi ID",
	type_betting : "Loại cược",
	bet_point : "Chi trả",
	win_loss: "Thắng/Thua",
	print:"In",
	cancel :"Hủy",
};
